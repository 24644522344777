import React from "react";
// Customizable Area Start
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import OrderSummaryController, {
    countries,
    Props,
} from "./OrderSummaryController.web";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Box, Typography, Breadcrumbs, Button, Grid, FormLabel, TextField, Checkbox ,Modal, IconButton } from "@material-ui/core";
import WebHeader from "../../landingpage/src/WebHeader.web";
import WebFooter from "../../../components/src/Footer.web";
import MyLocationTwoToneIcon from '@material-ui/icons/MyLocationTwoTone';
import { deleteIcon, accept } from './assets'
import CloseIcon from '@material-ui/icons/Close'

const theme = createTheme({
    overrides: {
        MuiTypography: {
            h1: {
                color: "#0F172A",
                fontFamily: "Lato",
                fontSize: "42px",
                lineHeight: "54px",
                fontWeight: 600,
                
            },  
            h3: {
                color: "#000000",
                fontFamily: "Lato",
                fontSize: "32px",
                fontWeight: 700,
                lineHeight: "54px",
            },
            h2: {
                color: "#0F172A",
                fontFamily: "Lato",
                fontSize: "38px",
                fontWeight: 700,
                lineHeight: "54px",
            },
            h6: {
                fontFamily: "Lato",
                fontSize: "20px",
                fontWeight: 700,
                lineHeight: "28px",
            },
            subtitle1: {
                fontFamily: "Lato",
                fontSize: "22px",
                fontWeight: 600,
                lineHeight: "54px",
            },
            body1: {     
                fontSize: "24px",
                fontFamily: "Lato",
                fontWeight: 400,
                lineHeight: "40px",
            },
            body2: {
                fontFamily: "Lato",
                fontSize: "18px",
                lineHeight: "24px",
                fontWeight: 400,
            },
        },

    },
});
const CustomTextField = styled(TextField)({
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            border: "none",
        },
        "& input": {
            padding: "12px 14px",
            marginTop: "5px",
        },
    },
    width: "100%",
});
// Customizable Area Start
const ClampedTypography = styled(Typography)(({ theme }) => ({
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
}));
// Customizable Area End
export default class OrderSummary extends OrderSummaryController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const { productData,  billingSameAsShipping, errors } = this.state;
        return (
            <ThemeProvider theme={theme}>
                <Wrapper>
                    <WebHeader navigation={this.props.navigation} />

                    <Box className="container">

                        {productData && (
                            <Box>
                                <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className="breadCrumb">
                                    <Typography style={{ color: "#475569", fontFamily: "Lato", fontSize: "16px", fontWeight: 600 }} onClick={() => this.navigateToShop()} data-test-id="shopBtn">
                                        Shop
                                    </Typography>
                                    <Typography color="textPrimary" style={{ color: "#475569", fontFamily: "Lato", fontSize: "16px", fontWeight: 600 }} onClick={() => this.handleProductNavigation(productData.category_id)} data-test-id="productNavigate">{productData.category_name}</Typography>
                                    <Typography color="textPrimary" style={{ color: "#475569", fontFamily: "Lato", fontSize: "16px", fontWeight: 600 }} onClick={() => this.handleShowProductNavigation(productData.id)} data-test-id="singleuser">{productData.product_name}</Typography>
                                    <Typography style={{ color: "#0F172A", fontFamily: "Lato", fontSize: "16px", fontWeight: 600 }} >
                                        Checkout
                                    </Typography>
                                </Breadcrumbs>
                                { this.state.delete&&(  <Box style={{ width: "100%", boxShadow: "none" }}>
                                    <Box display="flex" alignItems="center" justifyContent="space-between" width="100%" mb={2}>
                                        <Typography variant="h6" style={{ flex: 2 }}>Product</Typography>
                                        <Typography variant="h6" style={{ flex: 1, textAlign: 'center' }}>Price</Typography>
                                        <Typography variant="h6" style={{ flex: 1, textAlign: 'center', paddingRight: "9px" }}>Quantity</Typography>
                                        <Typography variant="h6" style={{ flex: 1, textAlign: 'center', paddingRight: "33px" }}>Total Cost</Typography>
                                        <Box style={{ flex: 0 }}>
                                        </Box>
                                    </Box>
                                    <Box display="flex" alignItems="center" justifyContent="space-between" py={2} boxShadow="none" borderRadius={4} border="none" flexDirection="row">

                                        <Box display="flex" alignItems="center" flex={2}>
                                            <img
                                                src={productData.main_image}
                                                alt="Moonstone silver ring"
                                                style={{ width: '150px', height: '125px', marginRight: '16px', borderRadius: '8px' }}
                                            />
                                            <Box>
                                                <Typography variant="h6" color="textPrimary">{productData.product_name}</Typography>
                                                <ClampedTypography variant="body2" color="textSecondary">
                                                    {productData.description}
                                                </ClampedTypography>
                                                <Button variant="text" color="primary" style={{ marginTop: '8px' }}  onClick={() => this.handleShowProductNavigation(productData.id)} data-test-id="singleuser">View Product</Button>
                                            </Box>
                                        </Box>
                                        <Box flex={1} textAlign="center" flexDirection="row" justifyContent="space-between">
                                            <Typography variant="body1">
                                                <span style={{ color: '#0F172A' }}>${productData.price}</span>
                                                <span style={{ color: '#A0AEC0', textDecoration: 'line-through', marginRight: '8px' }}>$32</span>
                                            </Typography>
                                        </Box>
                                        <Box display="flex" alignItems="center" flex={1} justifyContent="center">
                                            <Button variant="outlined" onClick={this.decrement} style={{ minWidth: '30px', padding: '5px' }} data-test-id="decrement">-</Button>
                                            <Box mx={2} width="40px" textAlign="center">
                                                <Typography>{this.state.count}</Typography>
                                            </Box>
                                            <Button variant="outlined" onClick={this.increment} style={{ minWidth: '30px', padding: '5px' }} data-test-id="increment">+</Button>
                                        </Box>
                                        <Box flex={1} textAlign="center">
                                            <Typography variant="body1">${this.state.subTotal}</Typography>
                                        </Box>
                                        <img src={deleteIcon}  onClick={() => this.handelDelete()} data-test-id="deleteBtn"/>
                                    </Box>
                                </Box>
                                )}
                            </Box>
                        )}
                       {this.state.delete && ( <Grid className="mainFormGrid">
                            <Grid item xl={6} className="formMainBox">

                                <Box className="formHeading">
                                    <Typography style={{
                                        color: "#0F172A",
                                        fontFamily: "Lato",
                                        fontSize: "20px",
                                        fontWeight: 600,
                                    }}>Shipping  Address</Typography>
                                    <Box className="checkBox">
                                        <Checkbox
                                            color="primary"
                                            style={{ color: "#A190C2", borderRadius: "30px" }}
                                            inputProps={{ "aria-label": "secondary checkbox" }}
                                            checked={this.state.isChecked}
                                            onChange={this.handleCheckboxChange}
                                            // disabled={!this.state.isChecked}
                                        />
                                        <Typography
                                            style={{
                                                color: "#0F172A",
                                                fontFamily: "Lato",
                                                fontSize: "16px",
                                                fontWeight: 400,
                                            }}
                                        >
                                            Save address
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box className="addressForm">
                                    <Box className="textBox">
                                        <FormLabel component="label" className="labelTxt">
                                            Address line 1
                                            <span style={{ color: "red" }}>*</span>
                                        </FormLabel>
                                        <Box className="firstLine">

                                            <MyLocationTwoToneIcon style={{ color: "#94A3B8" }} />
                                            <CustomTextField
                                            data-test-id="shippingInput"
                                                placeholder="Enter your address"
                                                variant="outlined"
                                                fullWidth
                                                value={this.state.shippingAddress.line1}
                                                onChange={this.handleShippingChange('line1')}
                                            />
                                        </Box>
                                        {errors.shippingAddress.line1 && <Typography className="error">{errors.shippingAddress.line1}</Typography>}
                                    </Box>
                                    <Box className="textBox" >
                                        <FormLabel component="label" className="labelTxt">
                                            Address line 2
                                            <span style={{ color: "red" }}>*</span>
                                        </FormLabel>
                                        <Box className="firstLine">
                                            <CustomTextField
                                            data-test-id="shippingInput"
                                                placeholder="Enter your address"
                                                variant="outlined"
                                                fullWidth
                                                value={this.state.shippingAddress.line2}
                                                onChange={this.handleShippingChange('line2')}
                                            />
                                        </Box>
                                        {errors.shippingAddress.line2 && <Typography className="error">{errors.shippingAddress.line2}</Typography>}
                                    </Box>
                                    <Box className="textBox" >
                                        <FormLabel component="label" className="labelTxt">
                                            Landmark (if required)
                                        </FormLabel>
                                        <Box className="firstLine">
                                            <CustomTextField
                                            data-test-id="shippingInput"
                                                placeholder="Landmark"
                                                variant="outlined"
                                                fullWidth
                                                value={this.state.shippingAddress.landmark}
                                                onChange={this.handleShippingChange('landmark2')}
                                            />
                                        </Box>
                                        <Box className="national">
                                            <Box className="cityBox">
                                                <FormLabel component="label" className="labelTxt">
                                                    Town/City <span style={{ color: "red" }}>*</span>
                                                </FormLabel>
                                                <Box className="city">
                                                    <CustomTextField
                                                    data-test-id="shippingInput"
                                                        placeholder="Enter your Town/City"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={this.state.shippingAddress.city}
                                                        onChange={this.handleShippingChange('city')}
                                                    />
                                                </Box>
                                                {errors.shippingAddress.city && <Typography className="error">{errors.shippingAddress.city}</Typography>}
                                            </Box>

                                            <Box className="countryBox">
                                                <FormLabel component="label" className="labelTxt">
                                                    Country <span style={{ color: "red" }}>*</span>
                                                </FormLabel>
                                                <Box className="country">
                                                    <select
                                                    data-test-id="shippingCountry"
                                                        id="countryDropdown"
                                                        value={this.state.shippingAddress.country}
                                                        onChange={this.handleCountryChange} 
                                                        className="select"
                                                    >
                                                        <option value="" disabled>
                                                            -- Select Country --
                                                        </option>
                                                        {countries.map((country) => (
                                                            <option key={country.value} value={country.name}>
                                                                {country.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </Box>
                                                {errors.shippingAddress.country && <Typography className="error">{errors.shippingAddress.country}</Typography>}
                                            </Box>
                                        </Box>
                                        <Box>
                                            <Box className="cityBox">
                                                <FormLabel component="label" className="labelTxt">
                                                    Post Code <span style={{ color: "red" }}>*</span>
                                                </FormLabel>
                                                <Box className="city">
                                                    <CustomTextField
                                                    data-test-id="shippingInput"
                                                        placeholder="Enter your Post Code"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={this.state.shippingAddress.postCode}
                                                        onChange={this.handleShippingChange('postCode')}
                                                    />
                                                </Box>
                                            </Box>
                                            {errors.shippingAddress.postCode && <Typography className="error">{errors.shippingAddress.postCode}</Typography>}

                                        </Box>
                                    </Box>
                                    <Box className="checkBox">
                                        <Checkbox
                                        data-test-id="checkBx"
                                            color="primary"
                                            style={{ color: "#A190C2", borderRadius: "30px" }}
                                            inputProps={{ "aria-label": "secondary checkbox" }}
                                            checked={billingSameAsShipping}
                                            onChange={this.handleCheckboxChange}
                                        />
                                        <Typography
                                            style={{
                                                color: "#0F172A",
                                                fontFamily: "Lato",
                                                fontSize: "16px",
                                                fontWeight: 400,
                                            }}
                                        >
                                            Billing address same as Shipping address
                                        </Typography>
                                    </Box>
                                </Box>
                                {!this.state.billingSameAsShipping && (
                                    <Box className="billing">
                                        <Box><Typography style={{
                                            color: "#0F172A",
                                            fontFamily: "Lato",
                                            fontSize: "20px",
                                            fontWeight: 600,
                                        }}>Billing  Address</Typography></Box>
                                        <Box className="addressForm">
                                            <Box className="textBox">
                                                <FormLabel component="label" className="labelTxt">
                                                    Address line 1
                                                    <span style={{ color: "red" }}>*</span>
                                                </FormLabel>
                                                <Box className="firstLine">

                                                    <MyLocationTwoToneIcon style={{ color: "#94A3B8" }} />
                                                    <CustomTextField
                                                    data-test-id="billingInput"
                                                        placeholder="Enter your address"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={this.state.billingAddress.line1}
                                                        onChange={(e) => this.handleBillingChange('line1', e.target.value)}
                                                    />
                                                </Box>
                                                {errors.billingAddress.line1 && <Typography className="error">{errors.billingAddress.line1}</Typography>}

                                            </Box>
                                            <Box className="textBox" >
                                                <FormLabel component="label" className="labelTxt">
                                                    Address line 2
                                                    <span style={{ color: "red" }}>*</span>
                                                </FormLabel>
                                                <Box className="firstLine">
                                                    <CustomTextField
                                                        placeholder="Enter your address"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={this.state.billingAddress.line2}
                                                        onChange={(e) => this.handleBillingChange('line2', e.target.value)}
                                                    />

                                                </Box>
                                                {errors.billingAddress.line2 && <Typography className="error">{errors.billingAddress.line2}</Typography>}
                                            </Box>
                                            <Box className="textBox" >
                                                <FormLabel component="label" className="labelTxt">
                                                    Landmark (if required)
                                                </FormLabel>
                                                <Box className="firstLine">
                                                    <CustomTextField
                                                    data-test-id="billingInput"
                                                        placeholder="Landmark"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={this.state.billingAddress.landmark}
                                                        onChange={(e) => this.handleBillingChange('landmark', e.target.value)}
                                                    />
                                                </Box>
                                                <Box className="national">
                                                    <Box className="cityBox">
                                                        <FormLabel component="label" className="labelTxt">
                                                            Town/City <span style={{ color: "red" }}>*</span>
                                                        </FormLabel>
                                                        <Box className="city">
                                                            <CustomTextField
                                                            data-test-id="billingInput"
                                                                placeholder="Enter your Town/City"
                                                                variant="outlined"
                                                                fullWidth
                                                                value={this.state.billingAddress.city}
                                                                onChange={(e) => this.handleBillingChange('city', e.target.value)}
                                                            />
                                                        </Box>
                                                        {errors.billingAddress.city && <Typography className="error">{errors.billingAddress.city}</Typography>}
                                                    </Box>

                                                    <Box className="countryBox">
                                                        <FormLabel component="label" className="labelTxt">
                                                            Country <span style={{ color: "red" }}>*</span>
                                                        </FormLabel>
                                                        <Box className="country">

                                                        <select
                                                        data-test-id="billingCountry"
                                                        className="select"
                                                        id="countryDropdown"
                                                        value={this.state.billingAddress.country}
                                                        onChange={this.handleBillingCountryChange}
                                                    >
                                                        <option value="" disabled>
                                                            -- Select Country --
                                                        </option>
                                                        {countries.map((country) => (
                                                            <option key={country.value} value={country.name}>
                                                                {country.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                        </Box>
                                                        {errors.billingAddress.country && <Typography className="error">{errors.billingAddress.country}</Typography>}
                                                    </Box>
                                                </Box>
                                                <Box>
                                                    <Box className="cityBox">
                                                        <FormLabel component="label" className="labelTxt">
                                                            Post Code <span style={{ color: "red" }}>*</span>
                                                        </FormLabel>
                                                        <Box className="city">
                                                            <CustomTextField
                                                            data-test-id="billingInput"
                                                                placeholder="Enter your Post Code"
                                                                variant="outlined"
                                                                fullWidth
                                                                value={this.state.billingAddress.postCode}
                                                                onChange={(e) => this.handleBillingChange('postCode', e.target.value)}
                                                            />
                                                        </Box>
                                                        {errors.billingAddress.postCode && <Typography className="error">{errors.billingAddress.postCode}</Typography>}
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                )}
                            </Grid>
                            <Grid item xl={6} className="formPaymentBox">
                                <Box className="formPayBox">
                                    <Typography style={{
                                        color: "#0F172A",
                                        fontFamily: "Lato",
                                        fontSize: "20px",
                                        fontWeight: 600,
                                    }}>Order Summary</Typography>
                                    <Box className="subTotal">
                                        <Typography className="text">Subtotal</Typography>
                                        <Typography className="text">${this.state.subTotal}</Typography>
                                    </Box>
                                    <Box className="subTotal">
                                        <Typography className="text">Delivery Charges</Typography>
                                        <Typography className="text">${this.state.deliveryCharges}</Typography>
                                    </Box>
                                    <Box className="dotLine"></Box>
                                    <Box className="subTotal">
                                        <Typography className="total">Total Payable Amount</Typography>
                                        <Typography className="total">${this.state.totalPayable}</Typography>
                                    </Box>
                                </Box>
                                <Box className="payBtn">
                                    <Button style={{ color: "#FFFFFF", alignSelf: "center", fontWeight: 700, fontFamily: "Lato", fontSize: "18px" }} onClick={() => this.payBtn()} data-test-id="payBtn">Confirm and Pay</Button>
                                </Box>
                            </Grid>
                        </Grid>)}
                        {!this.state.delete &&(
                            <Box className="noProduct">
                                <Typography>No Product Found</Typography>
                            </Box>
                        )}
                    </Box>
                    <WebFooter navigation={this.handleNavigation} data-test-id="HandelFooter" />
                    <Modal
                        open={this.state.open}
                        onClose={this.handleClose}
                        aria-labelledby="modal-title"
                        aria-describedby="modal-description"
                    >
                        <Box style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 400,
                            backgroundColor: 'white',
                            boxShadow: "24",
                            padding: 19,
                            borderRadius: 16
                        }}>
                            <Box display={'flex'} justifyContent={'flex-end'}>
                                <IconButton
                                    aria-label="close"
                                    onClick={this.handleClose}
                                    style={{ position: 'absolute', right: 8, top: 8 }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Box>

                            {this.state.accept && !this.state.reject && (
                                <Box textAlign={'center'}>
                                    <Box textAlign={'center'} marginTop={4}>
                                        <img src={accept} />
                                        <Typography variant="h6" gutterBottom style={{ color: "#0F172A", fontSize: "24px", fontWeight: 700 }}>Order placed successfully!</Typography>
                                    </Box>

                                    <Typography className="content">
                                        Your order has been placed successfully. You can track your order status anytime from “My Orders”                                    </Typography>
                                    <Box style={{ display: "flex", justifyContent: "space-around", marginTop: '16px' }}>
                                        <Button variant="outlined" style={{ textTransform: "none" }} className="reTryBtn" onClick={() => this.navigateToShop} >Continue Shopping</Button>
                                        <Button data-test-id="btnsendlink" onClick={() =>this.navigateToLandingPage()}
                                            variant="contained" style={{ border: "1px solid #9176C4", padding: "16px", color: "#FFFFFF", backgroundColor: "#9176C4", borderRadius: "50px", height: "56px", width: "190px", fontWeight: 600, fontFamily: "Lato", textTransform: "none" }}>
                                            Go Home
                                        </Button>
                                    </Box>
                                </Box>
                            )}

                            {this.state.reject && !this.state.accept && (
                                <Box textAlign={'center'}>
                                    <Box textAlign={'center'} marginTop={4}>
                                        <Typography variant="h6" gutterBottom style={{ color: "#0F172A", fontSize: "24px", fontWeight: 700 }}>Failed</Typography>
                                    </Box>

                                    <Typography className="content">
                                        Your order could not be placed. Please try again or check your order status in 'My Orders'.                                    </Typography>

                                    <Box style={{ display: "flex", justifyContent: "space-around", marginTop: '16px' }}>
                                        <Button variant="outlined" style={{ textTransform: "none" }} className="reTryBtn" onClick={() => this.handleClose} data-test-id="closeBtn">Re-try Payment</Button>
                                        <Button data-test-id="btnresendlink" onClick={() =>this.navigateToLandingPage()}
                                            variant="contained" style={{ border: "1px solid #9176C4", padding: "16px", color: "#FFFFFF", backgroundColor: "#9176C4", borderRadius: "50px", height: "56px", width: "190px", fontWeight: 600, fontFamily: "Lato", textTransform: "none" }}>
                                            Go Home
                                        </Button>
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    </Modal>
                </Wrapper>
            </ThemeProvider>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const Wrapper = styled("div")(({ theme }) => ({
    "& .container": {
        padding: "20px 42px",
        backgroundColor: "#FAFFF3",
    },
    "& .imageSection": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderRadius: '8px'
    },
    "& .mainImageCard": {
        width: "90%",
        marginBottom: "5px",
        height: "512px",
        borderRadius: "8px"
    },
    "& .detailsSection": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        padding: "17px",
        paddingRight: "25px"
    },
    "& .quantitySection": {
        display: "flex",
        alignItems: "center",
        margin: "20px 0",
    },
    "& .quantityInput": {
        width: "50px",
        marginLeft: "10px",
    },
    "& .tab, & .activeTab": {
        padding: "10px 20px",
        cursor: "pointer",
        flexGrow: 1,
        textAlign: "center",
    },
    "& .descriptionText": {
        fontSize: "18px",
        lineHeight: "26px",
        color: "#1E293B",
        fontFamily: "Lato",
        fontWeight: 400,
        Paragraph: "12px",
        paddingRight: "45px",
        height: "300px"
    },
    "& .desc": {
        marginRight: "30px"
    },
    "& .inc": {
        backgroundColor: "#F5F5F5",
        padding: "6px",
        margin: "10px"
    },
    "& .dec": {
        backgroundColor: "#F5F5F5",
        padding: "6px",
        margin: "10px",
        paddingLeft: "10px",
        paddingRight: "10px"
    },
    "& .breadCrumb": {
        paddingTop: "15px"
    },
    "& .totalprice": {
        display: "flex",
        flexDirection: "column",
    },
    "& .formMainBox": {
        border: "1px solid #CEC3E54D",
        display: "flex",
        flexDirection: "column",
        marginRight: "5px",
    },
    "& .add": {
        display: "flex",
        marginTop: "20px",
    },
    "& .formMainBox, & .formPaymentBox": {
        width: "50%",
        padding: "15px",
        boxSizing: "border-box",
    },
    "& .mainFormGrid": {
        display: "flex",
        justifyContent: "center",
        flexDirection: "row",
        marginTop: "20px"
    },
    "& .formPaymentBox": {
        display: "flex",
        flexDirection: "column",
        paddingLeft: "15px",
        marginLeft: "5px",

    },
    "& .formHeading": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: "20px",
    },
    "& .checkBox": {
        display: "flex",
        flexDirection: "row",
    },
    "& .subTotal": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    "& .text": {
        color: "#1E293B",
        fontSize: "16px",
        fontWeight: 500,
        fontFamily: "Lato",
    },
    "& .dotLine": {
        borderTop: "3px dotted #94A3B8",
        margin: "20px 0",
    },
    "& .total": {
        fontSize: "20px",
        color: "#9176C4",
        fontWeight: 700,
    },
    "& .firstLine": {
        border: "1px solid #CBD5E1",
        borderRadius: "8px",
        height: "48px",
        alignContent: "center",
        paddingInline: "17px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "7px",
    },
    "& .textBox": {
        marginBottom: "15px",
        marginTop: "7px"
    },
    "& .national": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: "15px",
        marginBottom: "15px",
    },
    "& .city": {
        border: "1px solid #CBD5E1",
        marginRight: "5px",
        borderRadius: "8px",
        height: "48px",
        marginTop: "7px"
    },
    "& .country": {
        border: "1px solid #CBD5E1",
        marginLeft: "5px",
        borderRadius: "8px",
        height: "48px",
        marginTop: "7px"
    },
    "& .cityBox ,& .countryBox": {
        width: "50%",
        boxSizing: "border-box",
    },
    "& .payBtn": {
        backgroundColor: "#A190C2",
        width: "386px",
        alignSelf: "center",
        borderRadius: "100px",
        height: "48px",
        textAlign: "center",
        marginTop: "35px"
    },
    "& .formPayBox": {
        border: "1px solid #CEC3E54D",
        marginTop: "-16px",
        padding: "10px",
    },
    "& .labelTxt": {
        fontSize: "14px",
        fontFamily: "Lato",
        color: "#334155",
        fontWeight: 400,
        marginBottom: "10px"
    },
    "& .reTryBtn": {
        border: "1px solid #9176C4",
        padding: "16px",
        color: "#9176C4",
        borderRadius: "50px",
        height: "56px",
        width: "190px",
        fontWeight: 600,
        fontFamily: "Lato"
    },
    "& .content": {
        fontWeight: 400,
        color: "#64748B",
        fontSize: "18px",
    },
    "& .select":{
        backgroundColor: "white",
        border: "none",
        height: "95%",
        width:"95%",
        marginLeft:'5px'
    },
    "& .error":{
        fontSize:"15px",
        color:"red"
    },
    "& .noProduct":{
        height:"60vh",
        display:"flex",
        justifyContent:"center",
        alignItems:"center"
    }

    // Reasons to choose section
    //     [theme.breakpoints.down(500)]: {


    //    },
}));
// Customizable Area End
