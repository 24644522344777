import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import * as Yup from "yup";
import { FormikValues } from "formik";
import { sendAPIRequest } from "../../../components/src/Utils";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start  
    openDialog: "emailSent" | "resentLink" | null;
    email: string;
    errorMessage: string;
    openSnackbar: boolean;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class ForgotPasswordController extends BlockComponent<
Props,
  S,
  SS
  > {
    // Customizable Area Start
    callForgotPasswordApiId: string = "";
    // Customizable Area End

    constructor(props: Props) {
      super(props);
      this.receive = this.receive.bind(this);

      this.subScribedMessages = [
        getName(MessageEnum.AccoutLoginSuccess),
        // Customizable Area Start
        getName(MessageEnum.RestAPIResponceMessage),
        // Customizable Area End
      ];

      this.state = {
        // Customizable Area Start
        openDialog: null,
        email: "",
        errorMessage: "",
        openSnackbar: false,
        // Customizable Area End
      };
      runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

      // Customizable Area Start
      // Customizable Area End
    }

    async receive(from: string, message: Message) {
      runEngine.debugLog("Message Recived", message);
      // Customizable Area Start
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJSON = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    this.apiSuccessCallBackController(apiRequestCallId, responseJSON);
      // Customizable Area End
    }

    // Customizable Area Start
    validationSchema = Yup.object().shape({
        email: Yup.string().email("Enter valid email").required("Email is required"),
      });

      apiSuccessCallBackController = (
        apiRequestCallId: string,
        responseJSON: Record<string, unknown>
      ) => {
        const successCallbackMap = {
          [this.callForgotPasswordApiId]: this.handleForgotPasswordApiResponse,
        }
     
        if (apiRequestCallId) {
          const successCallback: (responseJSON: Record<string, unknown>) => void = successCallbackMap[apiRequestCallId]
          !!successCallback && successCallback(responseJSON)
        }
      }

      handleForgotPasswordApiResponse = (responseJSON: Record<string, unknown>) => {
        if (this.handleErrorResponse(responseJSON)) return;

        this.setState({ errorMessage: '' });
        if(this.state.openDialog === "emailSent"){
          this.setState({ openDialog: "resentLink" });
        }
        else{
          this.setState({ openDialog: "emailSent"});
        }
        }

        handleErrorResponse = (responseJSON: Record<string, unknown>) => {
            const { errors: possibleErrors } = responseJSON;
            if (possibleErrors) {
                const errors = possibleErrors;
              if (Array.isArray(errors) && errors[0].email) {
                this.setState({ 
                  errorMessage: errors[0].email, 
                  openSnackbar: true, 
                  openDialog: null 
                });
              return true; // Indicates that there was an error
            }
            return false; // Indicates that there was no error
          }
        }
    
      handleSubmit = (values: FormikValues) => {
        this.setState({ email: values.email });
        this.sendEmail();
      }

      sendEmail = () => {
        this.callForgotPasswordApiId = sendAPIRequest(
            `/bx_block_signuplogin/forgot_passwords`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: {
                data: {
                  attributes: {
                    email: this.state.email,
                  },
                },
              }
            }
          )
      }

      reSendLink = () => {
        this.sendEmail();
      }
    
      handleClose = () => {
        this.setState({ openDialog: null, email: "" });
      }

      handleCloseSnackbar = () => {
        this.setState({ openSnackbar: false });
      }

      handleNavigation = (route: string) => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), route);
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
      }
    // Customizable Area End
  }
