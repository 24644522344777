import React from "react";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
  IconButton,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import ArrowBack from '@material-ui/icons/ArrowBack';

interface IModalProps {
  open: boolean;
  onClose?: () => void;
  onBack?: null | (() => void);
  customStyles?: { modal: {} };
  dataTest?: string;
  isCloseIcon?: boolean;
}

const theme = createTheme({
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: "#0F172A",
        opacity: "0.4 !important",
      },
    },
    MuiDialog: {
      paper: {
        maxWidth: '452px !important',
      }
    },
    MuiDialogContent: {
      root: {
        boxSizing: 'border-box',
        padding: "32px 32px",
        "&:first-child": {
          paddingTop: 34,
        },
        background: '#FFF',
        boxShadow: '0px 25px 50px 0px rgba(0, 0, 0, 0.09), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 8px 32px 0px rgba(0, 0, 0, 0.06)',
        borderRadius: '32px',
      },
    },
    MuiPaper: {
      root: {
        borderRadius: '32px !important',
      },
    },
  }
});

const webStyle = {
  dialog: {
    fontFamily: 'Quattrocento', // 'Lato',
  },
};

const GenericModal: React.FC<IModalProps> = ({ children, dataTest = 'generic-modal', open, onClose, onBack = null, isCloseIcon = true, customStyles = { modal: {} } }) => {
  return (
    <ThemeProvider theme={theme}>
      <Dialog fullWidth open={open} onClose={onClose} data-test={dataTest} style={{...webStyle.dialog,}}>
        <DialogContent style={{...customStyles.modal}}>
          {isCloseIcon && <IconButton style={{ position: "absolute", right: 12, top: 22, color: '#0F172A' }} aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>}
          {!!onBack && (
            <IconButton style={{ position: "absolute", left: 12, top: 22, color: '#0F172A' }} aria-label="back" onClick={onBack}>
              <ArrowBack />
            </IconButton>
          )}
          <div style={{ marginTop: 35, marginBottom: 0 }}>
            {children}
          </div>
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
};

export default GenericModal;