import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import { sendAPIRequest } from "../../../components/src/Utils";
import { Typography } from "@material-ui/core";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

type PasswordCriteriaKey = "uppercase" | "numerical" | "lowercase" | "length";
interface PasswordCriteria {
  uppercase?: boolean;
  numerical?: boolean;
  lowercase?: boolean;
  length?: boolean;
}
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    visiblePasswordField: boolean;
    visibleConfirmPasswordField: boolean;
    password: string;
    confirmPassword: string;
    passwordCriteria: PasswordCriteria;
    passwordError: string;
    confirmPasswordError: string;
    openDialog: boolean;
    errorMessage: string;
    openSnackbar: boolean;
    // Customizable Area End
}

interface SS {
id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class ResetPasswordController extends BlockComponent<
Props,
  S,
  SS
  > {
    // Customizable Area Start
    callResetPasswordApiId: string = "";
    // Customizable Area End

    constructor(props: Props) {
      super(props);
      this.receive = this.receive.bind(this);

      this.subScribedMessages = [
        getName(MessageEnum.AccoutLoginSuccess),
        // Customizable Area Start
        getName(MessageEnum.RestAPIResponceMessage),
        // Customizable Area End
      ];

      this.state = {
        // Customizable Area Start
        visiblePasswordField: false,
        visibleConfirmPasswordField: false,
        password: "",
        confirmPassword: "",
        passwordCriteria: {},
        passwordError: "",
        confirmPasswordError: "",
        openDialog: false,
        errorMessage: "",
        openSnackbar: false,
        // Customizable Area End
      };
      runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

      // Customizable Area Start
      // Customizable Area End
    }

    async receive(from: string, message: Message) {
      runEngine.debugLog("Message Recived", message);
      // Customizable Area Start
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJSON = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      this.apiSuccessCallBackController(apiRequestCallId, responseJSON)
      // Customizable Area End
    }

    // Customizable Area Start

    apiSuccessCallBackController = (
      apiRequestCallId: string,
      responseJSON: Record<string, unknown>
    ) => {
      const successCallbackMap = {
        [this.callResetPasswordApiId]: this.handleResetPasswordApiResponse,
      }
   
      if (apiRequestCallId) {
        const successCallback: (responseJSON: Record<string, unknown>) => void = successCallbackMap[apiRequestCallId]
        !!successCallback && successCallback(responseJSON)
      }
    }

    handleResetPasswordApiResponse = (responseJSON: Record<string, unknown>) => {
      if (this.handleErrorResponse(responseJSON)) return;
   
      this.setState({ errorMessage: '' });
      const response = responseJSON as { 
          message: string
      };
      if(response){
        this.setState({ openDialog: true });
      }
      }

      handleErrorResponse = (responseJSON: Record<string, unknown>) => {
          const { errors: possibleErrors } = responseJSON;
          if (possibleErrors) {
              const errors = possibleErrors;
            if (Array.isArray(errors) && errors[0]) {
              const errorMsg = Object.values(errors[0])[0] as string;
              this.setState({ errorMessage: errorMsg, openSnackbar: true });
            return true; // Indicates that there was an error
          }
          return false; // Indicates that there was no error
        }
      }

  resetPassword = () => {
    if(this.state.password !== this.state.confirmPassword){
      this.setState({confirmPasswordError: "The new password and confirm password does not match"});
      return;
    }
    const searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get("token");

    this.callResetPasswordApiId = sendAPIRequest(
      `/bx_block_signuplogin/change_passwords`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: {
          token: token,
          password: this.state.password,
          password_confirmation: this.state.confirmPassword
        },
      }
    )
  }

  validatePassword = () => {
    const { password } = this.state;
    const passwordCriteria = {
      uppercase: /[A-Z]/.test(password),
      numerical: /\d/.test(password),
      lowercase: /[a-z]/.test(password),
      length: password.length >= 8,
    };

    this.setState({ passwordCriteria });
  }

  handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ password: event.target.value });
    this.validatePassword();
  };

  handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ confirmPassword: event.target.value });
    if(this.state.password == this.state.confirmPassword){
      this.setState({ confirmPasswordError: ""});
    }
  };

    setEnablePasswordField = () => {
      this.setState({ visiblePasswordField: !this.state.visiblePasswordField });
    }

    setEnableConfirmPasswordField = () => {
      this.setState({ visibleConfirmPasswordField: !this.state.visibleConfirmPasswordField });
    }

    handleClose = () => {
      this.setState({ openDialog: false });
    }

    handleCloseSnackbar = () => {
      this.setState({ openSnackbar: false });
    }

    renderPasswordCriteria = () => {
      const criteriaText: Record<PasswordCriteriaKey, string> = {
        uppercase: "At least one Uppercase character (A-Z)",
        numerical: "At least one numerical (0-9)",
        lowercase: "At least one Lowercase character (a-z)",
        length: "Minimum 8 characters long",
      };
  
      return (["uppercase", "numerical", "lowercase", "length"] as PasswordCriteriaKey[]).map(
        (criterion) => {
          const criterionState = this.state.passwordCriteria[criterion];
          let criterionClass;

          if (criterionState === undefined) {
            criterionClass = "criteriaInitialText";
          } else if (criterionState) {
            criterionClass = "criteriaTextValid";
          } else {
            criterionClass = "criteriaText";
          }
  
          return (
            <Typography className={criterionClass} key={criterion}>
              <CheckCircleIcon fontSize="small" style={{ marginRight: "8px" }} />
              {criteriaText[criterion]}
            </Typography>
          );
        }
      );
    };

    handleNavigation = (route: string) => {
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), route);
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
    }
    // Customizable Area End
  }
