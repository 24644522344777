import React from 'react';
import { Field, FieldProps, getIn } from 'formik';
import { FormHelperText } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

interface ErrorMessageProps {
  name: string;
}

export default class ErrorMessage extends React.Component<ErrorMessageProps> {
  renderField = ({ form }: FieldProps) => {
    const { name } = this.props;
    const error = getIn(form.errors, name);
    const touch = getIn(form.touched, name);
    return touch && error ? (
        <FormHelperText style={{
            color: "#DC2626",
            fontSize: "12px",
            display: "flex",
            alignItems: "center",
          }}>
            <InfoOutlinedIcon style={{
                width: "16px", 
                height: "16px",
                marginRight: "2px",
                marginBottom: "3px",
            }} /> 
            {error}
        </FormHelperText>
      ) : null;
  };

  render() {
    const { name } = this.props;
    return <Field name={name} render={this.renderField} />;
  }
}