import {
    Box,
    Typography,
    styled,
    ThemeProvider,
    createTheme,
    Grid,
    Button,
  } from "@material-ui/core";
  import React from "react";
  import { logo} from "../../blocks/signuplogin/src/assets"
  import {
    GooglePlayButton,
    AppStoreButton,
    ButtonsContainer,
  } from "react-mobile-app-button";
import { FacebookIcon, InstagramIcon, TwitterIcon } from "./assets";



const Important =
[
  {
    id: "1",
    name: "Home",
    path: "LandingPage",
  },
  {
    id: "2",
    name: "About Us",
    path: "#",
  },
  {
    id: "3",
    name: "Shop",
    path: "NavigationMenu",
  },
  {
    id: "4",
    name: "Support",
    path: "#",
  },
  {
    id: "5",
    name: "Pricing",
    path: "#",
  },
];
const Others =
[
  {
    id: "1",
    name: "Report Death",
    path: "#",
  },
  {
    id: "2",
    name: "Final Pdf",
    path: "#",
  },
  {
    id: "3",
    name: "Contact us",
    path: "#",
  },
];
const Legal =
[
  {
    id: "1",
    name: "Terms of Use ",
    path: "Termsandconditions",
  },
  {
    id: "2",
    name: "Privacy Policy",
    path: "Privacypolicy",
  },
];
//Connect
const Connect =
[
  {
    id: "1",
    name: "Facebook",
    path: "#",
    icon: FacebookIcon,
  },
  {
    id: "2",
    name: "Instagram",
    path: "#",
    icon: InstagramIcon,
  },
  {
   id: "3",
   name: "Twitter",
   path: "#",
   icon: TwitterIcon,
 },
];

  const theme = createTheme({
    overrides: {
      MuiTypography: {
        h5: {
          fontFamily: "Lato",
          fontSize: "16px",
          fontWeight: 600,
          lineHeight: "24px",
        },
        h6: {
          fontFamily: "Lato",
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "28px",
        },
        body1: {
          fontFamily: "Lato",
          fontSize: "16px",
          fontWeight: 500,
          lineHeight: "24px",
          fontStyle: "italic",
        },
        subtitle2: {
          fontFamily: "Poppins",
          fontSize: "12px",
          fontWeight: 400,
          lineHeight: "20px",
        }
      },
    },
  });

  interface Props {
    navigation?: (path: string) => void;
    handleNavigation?: (path: string) => void;
    activeMenu?: string;
    drawerOpen?: boolean;
    handleMenuClick?: (id: string) => void;
    toggleDrawer?: () => void;
    isAuthenticated?: boolean;
  }
  
export default class WebFooter extends React.Component<Props> {
  constructor(props: any) {
    super(props);
  };
  
  render() {
    return (
      <ThemeProvider theme={theme}>
        <Wrapper>
          <Box className="footerWrapperBox">
            <Grid container spacing={4}>
              <Grid item md={4}>
                <Box className="leftContainer">
                  <Box className="logoWrapper">
                    <img src={logo} alt="logo" style={{ width: "52px", height: "29px" }} />
                  </Box>
                  <Typography variant="body1">
                    “Preserve your data and memories with for your loved ones to remember and cherish you after your demise or  buy products from our e-commerce store.”
                  </Typography>
                  <Box>
                    <ButtonsContainer>
                      <GooglePlayButton
                        url="android-app-url"
                        theme={"dark"}
                        className="apkBtn"
                      />
                      <AppStoreButton
                        url="ios-app-url"
                        theme={"dark"}
                        className="apkBtn"
                      />
                    </ButtonsContainer>
                  </Box>
                </Box>
              </Grid>
              <Grid item md={8}>
                <Box>
                  <Grid container spacing={4}>
                    <Grid item md={3}>
                      <Menus>
                        <Typography variant="h5">Important</Typography>
                        {Important.map((item: any) => (
                          <MenuItem key={item.id} data-test-id="handleMenuClick">
                            <Typography
                              variant="h6"
                              className="menuText"
                            >
                              {item.name}
                            </Typography>
                          </MenuItem>
                        ))}
                      </Menus>
                    </Grid>
                    <Grid item md={3}>
                      <Menus>
                        <Typography variant="h5">Others</Typography>
                        {Others.map((item: any) => (
                          <MenuItem key={item.id} data-test-id="handleMenuClick">
                            <Typography
                              variant="h6"
                              className="menuText"
                            >
                              {item.name}
                            </Typography>
                          </MenuItem>
                        ))}
                      </Menus>
                    </Grid>
                    <Grid item md={3}>
                      <Menus>
                        <Typography variant="h5">Legal</Typography>
                        {Legal.map((item: any) => (
                          <MenuItem key={item.id} data-test-id="handleMenuClick">
                            <Typography
                              variant="h6"
                              className="menuText"
                            >
                              {item.name}
                            </Typography>
                          </MenuItem>
                        ))}
                      </Menus>
                    </Grid>
                    <Grid item md={3}>
                      <Menus>
                        <Typography variant="h5">Connect with Us</Typography>
                        {Connect.map((item: any) => (
                          <MenuItem key={item.id} data-test-id="handleMenuClick">
                            <Box className="connectWithUsWrapper">
                              <img className="socialMediaIcons" alt="social-media-icon" src={item.icon} />
                              <Typography
                                variant="h6"
                                className="menuText"
                              >
                                {item.name}
                              </Typography>
                            </Box>
                          </MenuItem>
                        ))}
                      </Menus>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <div className="copyRightWrapperBox">
            <Typography variant="subtitle2">Copyright © 2020  Delegate Mee Pvt ltd.</Typography>
            <Button
              variant="text"
              className="linkBtns"
            >
              Terms & Conditions
            </Button>
            <Button
              variant="text"
              className="linkBtns"
            >
              Privacy Policy
            </Button>
          </div>
        </Wrapper>
      </ThemeProvider>
    );
  }
  };
  

  const Wrapper = styled('div')(({ theme }) => ({
    "& .footerWrapperBox": {
      display: "flex",
      justifyContent: "space-between",
      backgroundColor: "#ACCC86",
      minHeight: "300px",
      borderBottom: "1px solid #00000014",
      alignItems: "center",
      padding: "64px 92px",
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
      "@media (max-width:900px)": {
        padding: "16px",
      },
    },
      "& .leftContainer": {
        display: "flex",
        gap: "16px",
        flexDirection: "column",
      },
      "& .logoWrapper": {
        display: "flex", 
        alignItems: "center", 
        justifyContent: "center", 
        backgroundColor:"white",
        padding:"5px", 
        borderRadius: "4px", 
        width: "86px", 
        height: "44px"
      },
      "& .apkBtn": {
        width: "120px !important",
        height: "38px !important",
        borderRadius: "8px",
        boxSizing: "border-box",
      },
      "& .menuText": {
        color: "#0F172A",
        textDecoration: "none",
      },
      "& .activeMenuText": {
        color: "#9176C4",
        textDecoration: "underline",
        textUnderlinePosition: "under",
      },
      "& .connectWithUsWrapper": {
        display: "flex",
        gap: "8px",
      },
      "& .socialMediaIcons": {
        width: "20px",
        height: "20px",
      },
      "& .copyRightWrapperBox": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "42px",
      },
      "& .linkBtns": {
        fontFamily: "Lato",
        fontSize: "12px",
        fontWeight: 600,
        lineHeight: "18px",
        textDecoration: "underline", 
        textUnderlineOffset: "3px",
        textTransform: "none"
      },
      //react-mobile-app-button css override
      "& .button-container": {
        padding: "4px",
      },
      "& .button-container>img": {
        width: "24px",
        height: "24px",
      },
      "& .button-title": {
        fontSize: "8px",
      },
      "& .button-store-name": {
        fontSize: "13px",
      },
  }));
  
  const Menus = styled(Box)({
    display: "flex", 
    gap: "10px",
    flexDirection:"column",

  });
  
  const MenuItem = styled(Box)({
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    gap: "6px",
  });
