import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";

type HttpMethods = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'
interface RequestParams {
  method?: HttpMethods;
  headers?: object;
  body?: object | FormData | any;
}

export function sendAPIRequest(endpoint: string, params?: RequestParams) {
    const { method = "GET", headers, body } = params || {}
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    
    const callId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    )
    if (body)
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body?.append ? body : JSON.stringify(body)
      )
   
    if (headers)
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        headers
      )
    runEngine.sendMessage(requestMessage.id, requestMessage)
    return callId
  };

  export function getLastPartOfURL() {
    let url = window.location.pathname;
    let parts = url.split("/");
    let lastPart = parts[parts.length - 1];
    return lastPart;
};