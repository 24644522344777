Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";
exports.getAllAboutsApiEndPoint = "bx_block_landingpage/all_abouts";
exports.getLoggedInUserApiEndPoint = "account_block/accounts/logged_user"; 

exports.btnExampleTitle = "CLICK ME";

exports.reasonsToChooseTitle = "Why Choose Delegate Me?";
exports.easyToUseTitle = "Easy to Use";
exports.easyToUseDescription = "Delegate Mee is perfectly suitable for all the individuals of every age group.";
exports.accessibleTitle = "Accessible";
exports.accessibleDescription = "All your cherished memories can be shared with loved ones through any device.";
exports.personalTitle = "Personal";
exports.personalDescription = "All your personal data saved in one place for your loved once to cherish after your demisse";
exports.secureTitle = "Secure";
exports.secureDescription = "Your memories can only be accessed by you and individuals authorized by you.";

exports.getStartedBtnText = "Get Started";
exports.loginBtnText = "Login";
exports.signupBtnText = "Sign Up";
// Customizable Area End