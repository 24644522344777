import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import * as Yup from "yup";

// Customizable Area Start
import React from "react";
import { getStorageData } from "../../../framework/src/Utilities";
import { sendAPIRequest } from "../../../components/src/Utils";


export interface IGetInvestmentsAndFundsAPIResponse {
      id: string;
      type: string;
      attributes: {
        id: number;
      have_banking_provider: string;
      bank_providers: {
        id: number;
        provider_name: string;
      }[];
      have_stocks: string;
      stock_details: {
        id: number;
        name: string;
        quantity: number;
        total_value: number;
      }[];
      have_premimum_bonds: string;
      bond_details: {
        id: number;
        holder_name: string;
        unit: number;
        no_of_unit: number;
        bond_value: number;
        files: any[];
      }[];
      account_id: number;
      };
    };


export interface IMedia {
    file_id: number;
    file_name: string;
    content_type: string;
    file_size: number;
    url: string;
};

export interface InvestmentsAndFundsInterface {
    investment: Investments,
    banking_provider: IBankingProvider[],
    stocks: IStock[],
    bonds: IBond[],
};

export interface Investments {
    have_banking_provider: string;
    have_stocks: string;
    have_premimum_bonds: string;
};
export interface IBankingProvider {
    provider_name: string;
};

export interface IStock {
    name: string;
    quantity: string;
    total_value: string;
};

export interface IBond {
    holder_name: string;
    unit: string;
    no_of_unit: string;
    bond_value: string;
    files: (File | IMedia)[];
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  investmentsAndFunds: InvestmentsAndFundsInterface;
  files: (IMedia | File)[][];
  showBankDetailsForm: boolean;
  showStockDetailsForm: boolean;
  showBondDetailsForm: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class InvestmentsAndFundsController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
        investmentsAndFunds: {
            investment: {
                have_banking_provider: "0",
                have_premimum_bonds: "0",
                have_stocks: "0",
            },
            banking_provider: [{
                provider_name: "",
            }],
            stocks: [{
                name: "",
                quantity: "",
                total_value: "",
            }],
            bonds: [{
                holder_name: "",
                unit: "",
                no_of_unit: "",
                bond_value: "",
                files: [],
            }],
        },
        files: [],
        showBankDetailsForm: false,
        showStockDetailsForm: false,
        showBondDetailsForm: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJSON = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    
    this.apiSuccessCallBackController(apiRequestCallId, responseJSON);
    // Customizable Area End
  }

  // Customizable Area Start
  callGetInvestmentsAndFundsApiId: string = "";
  callUpsertInvestmentsAndFundsApiId: string = "";

  async componentDidMount() {
    super.componentDidMount();
    this.getInvestmentsAndFunds();
  }

  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJSON: Record<string, unknown>
  ) => {
    const successCallbackMap = {
      [this.callGetInvestmentsAndFundsApiId]: this.handleGetInvestmentsAndFundsApiResponse,
      [this.callUpsertInvestmentsAndFundsApiId]: this.handleUpsertInvestmentsAndFundsApiResponse,

    };

    if (apiRequestCallId) {
      const successCallback: (responseJSON: Record<string, unknown>) => void =
        successCallbackMap[apiRequestCallId];
      !!successCallback && successCallback(responseJSON);
    }
  };

  handleErrorResponse = (responseJSON: Record<string, unknown>) => {
    const { errors: possibleErrors } = responseJSON;
    if (possibleErrors) {
      return true; // Indicates that there was an error
    }
    return false; // Indicates that there was no error
  };

  getInvestmentsAndFunds = async() => {
    const token = await getStorageData("token");

    this.callGetInvestmentsAndFundsApiId = sendAPIRequest(
        configJSON.getInvestmentsAndFundsApiEndPoint,
        {
          method: configJSON.fetchFormDataMethod,
          headers: {
            token,
          },
        }
      );
  };

  handleInvestmentsAndFundsFormSubmit = async (values: InvestmentsAndFundsInterface) => {
    const token = await getStorageData("token");
    const formData = new FormData();

    Object.entries(values.investment).forEach(([keyName, value]) => {
      formData.append(`investment[${keyName}]`, value);
    });

    values.banking_provider.forEach((bankProvider, index) => {
        Object.entries(bankProvider).forEach(([keyName, value]) => {
          if (value) {
            formData.append(`banking_provider[][${keyName}]`, value as string);
          }
        });
      });

    values.stocks.forEach((stock, index) => {
      Object.entries(stock).forEach(([keyName, value]) => {
        if (value) {
          formData.append(`stock[][${keyName}]`, value as string);
        }
      });
    });

    values.bonds.forEach((bond, index) => {
        Object.entries(bond).forEach(([keyName, value]) => {
          if (value) {
            if(keyName === 'files' && bond.files && bond.files.length > 0){
                if(this.state.files.length > 0 && !(this.state.files[0]).hasOwnProperty('content_type')){
                  bond.files.forEach((file: string | IMedia | File | ArrayBuffer) => (
                    formData.append(`bond[][${keyName}][]`, file as Blob)
                  ))
              }
              }
            else{
                formData.append(`bond[][${keyName}]`, value as string);
            }
          }
        });
    });


    this.callUpsertInvestmentsAndFundsApiId = sendAPIRequest(
        configJSON.getInvestmentsAndFundsApiEndPoint,
        {
          method: configJSON.formAPiMethod,
          headers: {
            token,
          },
          body: formData,
        }
    );
  };

  handleGetInvestmentsAndFundsApiResponse = (
    responseJSON: Record<string, unknown>
  ) => {
    if (this.handleErrorResponse(responseJSON)) { return; }
    
    const response = responseJSON as {
        meta?: { message: string };
        data?: IGetInvestmentsAndFundsAPIResponse;
      };
  
      if (response.data) {
        const attributes = response.data.attributes;
        if(attributes.have_banking_provider === "Yes"){
            this.setState({ showBankDetailsForm: true });
          }
          if(attributes.have_stocks === "Yes"){
            this.setState({ showStockDetailsForm: true });
          }
          if(attributes.have_premimum_bonds === "Yes"){
            this.setState({ showBondDetailsForm: true });
          }

        let investmentsAndFunds = {
            investment: {
                have_banking_provider: attributes.have_banking_provider === "Yes" ? "1" : "0",
                have_premimum_bonds: attributes.have_premimum_bonds === "Yes" ? "1" : "0",
                have_stocks: attributes.have_stocks === "Yes" ? "1" : "0",
            },
            banking_provider: attributes.bank_providers.map(
                (bankProvider) => {
                  return(
                    {
                        provider_name: bankProvider.provider_name,
                    }
                  )
                }
            ),
            stocks: attributes.stock_details.map(
                (stock) => {
                  return(
                    {
                        name: stock.name,
                        quantity: stock.quantity.toString(),
                        total_value: stock.total_value.toString(),
                    }
                  )
                }
              ),
            bonds: attributes.bond_details.map(
                (bond) => {
                  return(
                    {
                          holder_name: bond.holder_name,
                          unit: bond.unit.toString(),
                          no_of_unit: bond.no_of_unit.toString(),
                          bond_value: bond.bond_value.toString(),
                          files: bond.files.map((mediaFile) => {
                            return({
                              file_id: mediaFile.file_id,
                              file_name: mediaFile.file_name,
                              content_type: mediaFile.content_type,
                              file_size: mediaFile.file_size,
                              url: mediaFile.url,
                            })
                          }),
                    }
                  )
                }
              ),
        } as InvestmentsAndFundsInterface;
        this.setState({ investmentsAndFunds });
      }
  };

  handleUpsertInvestmentsAndFundsApiResponse = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) { 
      return; 
    }

    const response = responseJSON as {
      meta?: { message: string };
      data?: IGetInvestmentsAndFundsAPIResponse;
    };

    if (response.data) {
      this.handleNavigation("FamilyDetails");
    }
  };


  validationSchema = Yup.object().shape({
      banking_provider:  Yup.array().when(['investment.have_banking_provider'], {
        is: (have_banking_provider) => have_banking_provider === "1",
        then: Yup.array().of(Yup.object().shape({
            provider_name: Yup.string().nullable().required("Please enter provider name"),
        })),
        otherwise: Yup.array().of(Yup.object().nullable()),
      }),
      stocks:  Yup.array().when(['investment.have_stocks'], {
        is: (have_stocks) => have_stocks === "1",
        then: Yup.array().of(Yup.object().shape({
            name: Yup.string().nullable().required("Please enter bond name"),
            quantity: Yup.string().nullable().required("Please enter stock quantity"),
            total_value: Yup.number().typeError("Please enter number value only").nullable().nullable().required("Please enter total value"),
        })),
        otherwise: Yup.array().of(Yup.object().nullable()),
      }),
      bonds: Yup.array().when(['investment.have_premimum_bonds'], {
        is: (have_premimum_bonds) => have_premimum_bonds === "1",
        then: Yup.array().of(Yup.object().shape({
            holder_name: Yup.string().nullable().required("Please enter bond holder name"),
            unit: Yup.number().typeError("Please enter number value only").nullable().required("Please enter unit value"),
            no_of_unit: Yup.number().typeError("Please enter number value only").nullable().required("Please enter numbers of units"),
            bond_value: Yup.number().typeError("Please enter number value only").nullable().required("Please enter bond value"),
        })),
        otherwise: Yup.array().of(Yup.object().nullable()),
      }),
  });

  handleFileUpload = async(event: React.ChangeEvent<HTMLInputElement>, 
    index: number,
    setFieldValue:
    {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; 
        (arg0: string, arg1: string): void;
    }) => {
    if (event.target.files) {
      const newFiles = Array.from(event.target.files);
      this.setState((prevState) => {
        const updatedFiles = [...(prevState.files || [])];
        if (!updatedFiles[index]) {
          updatedFiles[index] = [];
        }
        updatedFiles[index] = [...updatedFiles[index], ...newFiles];
  
        return { files: updatedFiles };
      }, () => {
        // Set the field value after the state has been updated
        const currentFiles = this.state.files[index] || [];
        setFieldValue(`bonds.${index}.files`, currentFiles);
      });
    }
  };

  handleFileRemove = (fileIndex: number, 
    bondIndex: number,
    setFieldValue:
    {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; 
        (arg0: string, arg1: string): void;
    }) => {
        this.setState((prevState) => {
            const updatedFiles = [...(prevState.files || [])];
        
            // Ensure the bond's files are initialized
            if (updatedFiles[bondIndex]) {
              const bondFiles = [...updatedFiles[bondIndex]];
        
              // Remove the file at the specified index
              bondFiles.splice(fileIndex, 1);
              updatedFiles[bondIndex] = bondFiles;
            }
        
            return { files: updatedFiles };
          }, () => {
            // Set the field value for the updated bond files after the state is updated
            setFieldValue(`bonds.${bondIndex}.files`, [...this.state.files[bondIndex]]);
          });
  };

  handleNavigation = (route: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), route);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };
  // Customizable Area End
}
