import React from "react";

// Customizable Area Start
import {
    Box,
    Button,
    Grid,
    IconButton,
    Snackbar,
    TextField,
    ThemeProvider,
    Typography,
    createTheme,
    styled
} from "@material-ui/core";
import { VisibilityOffOutlined, VisibilityOutlined } from "@material-ui/icons";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Alert from '@material-ui/lab/Alert';
import { backimage, earth, logo } from "../../../blocks/signuplogin/src/assets";
import GenericModal from "../../../components/src/GenericModal";
import { rightTickImg } from "./assets";

const theme = createTheme({
    overrides: {
        MuiTypography: {
            h1: {
                color: "#0F172A",
                fontFamily: "Quattrocento",
                fontSize: "24px",
                fontWeight: 700,
                lineHeight: "32px",
            },
            h2: {
                color: "#475569",
                fontFamily: "Lato",
                fontSize: "20px",
                fontWeight: 500,
            },
            h5: {
                marginBottom: "15px",
                color: "#0F172A",
                fontFamily: "Quattrocento",
                fontSize: "20px",
                fontWeight: 700,
            },
            h6: {
                marginBottom: "20px",
                color: "#64748B",
                fontFamily: "Lato",
                fontSize: '16px',
                fontWeight: 400,
            },
        },
        MuiButton: {
            text: {
                color: "#9176C4",
                textTransform: "none",
                paddingTop: "0",
                fontFamily: "Lato",
                fontSize: '16px',
                fontWeight: 500,
            }
        },
        MuiInputBase: {
            root: {
                padding: "14px 10px",
            }
        }
    },
});

// Customizable Area End

import ResetPasswordController, {
    Props,
    configJSON,
} from "./ResetPasswordController.web";

export default class ResetPassword extends ResetPasswordController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                <ThemeProvider theme={theme}>
                    <Wrapper>
                        <Grid container className="container">
                            <Grid item xs={12} md={6}>
                                <Box>
                                    <img src={backimage} alt="Delegate Mee" className="image" width={'100%'} />
                                    <Box className="welcomeSection">
                                        <Typography variant="h1" >{configJSON.welcomeText}</Typography>
                                        <Typography variant="h2">{configJSON.welcomeBodytext}</Typography>
                                    </Box>
                                    <Box className="earthImgWrapper">
                                        <img src={earth} alt="Earth Globe" />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6} className="formGrid">
                                <Box className="formWrapper">
                                    <Box style={{
                                        marginBottom: "20px"
                                    }}>
                                        <img src={logo} alt="logo" />
                                    </Box>
                                    <Box className="resetPasswordContent">
                                        <Typography variant="h5"
                                            data-test-id="resetPasswordTitle"
                                        >
                                            {configJSON.resetPasswordText}
                                        </Typography>
                                        <Typography variant="h6">
                                            {configJSON.resetPasswordDescription}
                                        </Typography>
                                    </Box>
                                    <form>
                                        <label className="label">
                                            {configJSON.newPasswordFieldLabel}<span className="span">*</span>
                                        </label>
                                        <Box className="fieldWrapper">
                                            <TextField
                                                style={{ width: '80.1%' }}
                                                type={this.state.visiblePasswordField ? "text" : "password"}
                                                onChange={this.handlePasswordChange}
                                                placeholder="New Password"
                                                data-test-id="PasswordInputId"
                                                InputProps={{
                                                    disableUnderline: true,
                                                    style: {
                                                        width: '100%',
                                                        height: '',
                                                        border: 'none',
                                                    },
                                                }}
                                                InputLabelProps={{
                                                    shrink: false,
                                                }}
                                            />
                                            <IconButton
                                                edge="end"
                                                aria-label="toggle password visibility"
                                                style={{ marginRight: "8px" }}
                                                onClick={this.setEnablePasswordField}
                                            >
                                                {this.state.visiblePasswordField ? (
                                                    <VisibilityOutlined style={{ color: '#A190C2' }} />
                                                ) : (
                                                    <VisibilityOffOutlined style={{ color: '#A190C2' }} />
                                                )}
                                            </IconButton>
                                        </Box>
                                        {this.state.passwordError && (
                                            <Typography style={{ color: "red", fontSize: "14px" }}><InfoOutlinedIcon style={{ fontSize: '16px', marginRight: "2px" }} />{this.state.passwordError}</Typography>
                                        )}
                                        <label className="label">
                                            {configJSON.confirmPasswordFieldLabel}<span className="span">*</span>
                                        </label>
                                        <Box className="fieldWrapper">
                                            <TextField
                                                data-test-id="PasswordInputId"
                                                style={{ width: '80.1%' }}
                                                type={this.state.visibleConfirmPasswordField ? "text" : "password"}
                                                onChange={this.handleConfirmPasswordChange}
                                                placeholder="Confirm Password"
                                                InputProps={{
                                                    disableUnderline: true,
                                                    style: {
                                                        width: '100%',
                                                        height: '',
                                                        border: 'none',
                                                    },
                                                }}
                                                InputLabelProps={{
                                                    shrink: false,
                                                }}

                                            />
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={this.setEnableConfirmPasswordField}
                                                edge="end"
                                                style={{ marginRight: "8px" }}
                                            >
                                                {this.state.visibleConfirmPasswordField ? (
                                                    <VisibilityOutlined style={{ color: '#A190C2' }} />
                                                ) : (
                                                    <VisibilityOffOutlined style={{ color: '#A190C2' }} />
                                                )}
                                            </IconButton>
                                        </Box>
                                        {this.state.confirmPasswordError && (
                                            <Box className="errorBox">
                                                <InfoOutlinedIcon className="errorIcon" />
                                                <Typography className="errorText">
                                                    {this.state.confirmPasswordError}
                                                </Typography>
                                            </Box>
                                        )}
                                        <Box className="criteriaBox">
                                            {this.renderPasswordCriteria()}
                                        </Box>
                                        <Box display="flex" justifyContent="center">
                                            <Button
                                                fullWidth
                                                data-test-id="resetPasswordBtn"
                                                variant="contained"
                                                className="button"
                                                onClick={() => this.resetPassword()}
                                            >
                                                {configJSON.resetPasswordText}
                                            </Button>
                                        </Box>
                                    </form>
                                    <Typography variant="h6" align="center" style={{ color: "#0F172A" }}>
                                        {configJSON.rememberPasswordText}
                                        <Button
                                            variant="text"
                                            style={{ 
                                                textDecoration: 'underline',  
                                                textUnderlineOffset: '4px' 
                                            }}
                                            data-test-id="loginLink"
                                            onClick={() => this.handleNavigation("Signuplogin")}
                                        >
                                            {configJSON.loginLinkText}
                                        </Button>
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Wrapper>
                </ThemeProvider>
                <GenericModal open={this.state.openDialog} dataTest="password-updated" onClose={this.handleClose}>
                    <ThemeProvider theme={theme}>
                        <DialogWrapper>
                            <Box className="alignCenter">
                                <img alt="success" src={rightTickImg} className="rightTickImg" />
                                <Typography align="center" variant="h1" data-test-id="passwordUpdatedMsg">Password Updated Successfully!</Typography>
                                <Typography align="center" variant="h6" style={{ color: "#64748B", fontWeight: 400 }}>
                                    Your password has been successfully updated. Secure your account by logging in with your new password.
                                </Typography>
                            </Box>
                            <Box className="btnWrapper">
                                <Button
                                    fullWidth
                                    style={{ width: "100%" }}
                                    className="containedBtn"
                                    variant="contained"
                                    onClick={() => this.handleNavigation("Signuplogin")}
                                >
                                    {configJSON.backToLoginBtnText}
                                </Button>
                            </Box>
                        </DialogWrapper>
                    </ThemeProvider>
                </GenericModal>

                {this.state.errorMessage && (
                    <Snackbar
                        data-test-id="errorSnackbar"
                        autoHideDuration={4000}
                        open={this.state.openSnackbar}
                        onClose={this.handleCloseSnackbar}
                    >
                        <Alert
                            onClose={this.handleCloseSnackbar}
                            severity="error"
                        >
                            {this.state.errorMessage}
                        </Alert>
                    </Snackbar>
                )}
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const Wrapper = styled('div')(({ theme }) => ({
    "& .container": {
        minHeight: '100vh',
    },
    "& .image": {
        Width: '100%',
        height: '660',
        padding: 2,
        margin: 2,
    },
    "& .welcomeSection": {
        marginBottom: '150px',
        padding: 70,
        position: 'absolute',
        left: '50%',
        top: '80%',
        textAlign: 'center',
        transform: 'translate(-100%, -50%)',
        wordWrap: "break-word",
    },
    "& .earthImgWrapper": {
        position: 'absolute',
        top: '45%',
        left: '25%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
        justifyContent: "center",
    },
    "& .resetPasswordContent": {
        width: "327px",
    },
    "& .formWrapper": {
        margin: '16px auto',
        width: '405px',
    },
    "& .formGrid": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "fff",
    },
    "& .span": {
        color: "red",
        marginTop: "5px",
    },
    "& .label": {
        fontFamily: "Lato",
        fontSize: "14px",
        fontWeight: 400,
        color: "#334155",
        marginBottom: "10px",
    },
    "& .fieldWrapper": {
        height: '48px',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        flexDirection: 'row',
        border: '1px solid #C5CBC9',
        borderRadius: '8px',
        padding: '10px, 24px, 10px, 24px',
        marginBottom: '16px',
        marginTop: '8px',
    },
    "& .errorIcon": {
        marginRight: "5px",
    },
    "& .errorBox": {
        display: "flex",
        alignItems: "center",
        marginBottom: "10px",
        color: "red",
    },
    "& .errorText": {
        fontFamily: 'Lato',
        color: "red",
        fontSize: '14px',
        lineHeight: '22px',
        fontWeight: 400,
        display: "flex",
        alignItems: "center",
    },
    "& .button": {
        width: "372px",
        height: "48px",
        fontSize: "16px",
        fontFamily: "Lato",
        marginTop: "72px",
        marginBottom: 0,
        borderRadius: "46px",
        color: "#fff",
        backgroundColor: "#A190C2",
        textTransform: "none",
    },
    "& .criteriaBox": {
        marginTop: theme.spacing(2),
    },
    "& .criteriaInitialText": {
        fontFamily: 'Lato',
        fontSize: '14px',
        color: 'gray',
        fontWeight: 500,
        display: 'flex',
        alignItems: 'center',
    },
    "& .criteriaText": {
        fontFamily: 'Lato',
        fontSize: '14px',
        color: 'red',
        fontWeight: 500,
        display: 'flex',
        alignItems: 'center',
    },
    "& .criteriaTextValid": {
        fontFamily: 'Lato',
        fontSize: '14px',
        color: 'green',
        fontWeight: 500,
        display: 'flex',
        alignItems: 'center',
    },
}));

const DialogWrapper = styled('div')(({ theme }) => ({
    "& .btnWrapper": {
        display: "flex",
        gap: "8px",
        justifyContent: "center",
    },
    "& .alignCenter": {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        gap: "8px",
        width: "369px",
    },
    "& .emailSpan": {
        fontWeight: 700,
        fontColor: "Black",
    },
    "& .containedBtn": {
        width: "192px",
        heigh: "56px",
        backgroundColor: "#9176C4",
        color: "#FFFFFF",
        borderRadius: "100px",
        textTransform: "none",
        fontFamily: "Lato",
        fontSize: "16px",
        fontWeight: 600,
        padding: "16px",
    },
    "& .outlinedBtn": {
        width: "192px",
        heigh: "56px",
        color: "#9176C4",
        borderRadius: "100px",
        textTransform: "none",
        fontFamily: "Lato",
        fontSize: "16px",
        fontWeight: 600,
        padding: "16px",
    },
    "& .rightTickImg": {
        width: "72px",
        height: "72px",
    },
    [theme.breakpoints.down('xs')]: {
        "& .btnWrapper": {
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
        }
    }
}));
// Customizable Area End
