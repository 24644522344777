import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../../packages/framework/src/Utilities";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
//   webDrawer: boolean;
  token: any;
  productid:number;
  productData:any;
  selectedTab:any;
  products: any [];
  currentPage: number;
  isFirstPage: boolean,
  isLastPage: boolean,
  totalPages: number,
  productsPerPage: number;
  count:number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ShowProductController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetDataCallId: string = "";
  popularProductsCallId: string="";
  showProductCallId:string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.increment = this.increment.bind(this);
    this.decrement = this.decrement.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
    //   webDrawer: false,
      token: "",
      productid: 0,
      productData:null,
      selectedTab: "Description",
      products: [],
      currentPage: 1,
      isFirstPage: true,
      isLastPage: false,
      totalPages: 0,
      productsPerPage: 4,
      count: 1,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
        const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        runEngine.debugLog("API Response Received", response);
  
        if (apiRequestCallId === this.showProductCallId) {
            if (response?.data) {
                const productData = response.data.attributes;
                this.setState({ productData });
            }
        }
        if (apiRequestCallId === this.popularProductsCallId) {
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
              this.setState({products:responseJson.data,isFirstPage:responseJson.meta.is_first_page,isLastPage:responseJson.meta.is_last_page})
              
            }
      }  
    // Customizable Area End
  }

  // Customizable Area Start


  async componentDidMount() {
    super.componentDidMount();
    this.handlePopularProduct(this.state.currentPage)
    this.functioncall()
  }

functioncall =()=>{
  const productId = this.props.navigation.getParam("navigationBarTitleText"); 
     this.setState({ productid: productId }, () => {
         this.handleShowProduct(this.state.productid); 
     });
}
 
   handleShowProduct = async (productId: number) => {
    this.showProductCallId = await this.getApiCall({
        method: "GET",
        endPoint: `/bx_block_categories/products/${productId}`,
        contentType: "application/json",
    });
}
 handlePopularProduct = async(page: number) => {
    this.popularProductsCallId = await this.getApiCall({
      method: "GET",
      endPoint: `/bx_block_categories/products/popular_products?page=${page}`,
      contentType: "application/json",
    });
  };
  getApiCall = async (data: any) => {
    const token = await getStorageData("token");
    const { contentType, method, endPoint, body, type } = data;
    const request = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const header = { "Content-Type": contentType, token };
      
    request.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    request.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    request.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    body && type != "formData"
      ? request.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body)
        )
      : request.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);
    runEngine.sendMessage(request.id, request);
    return request.messageId;
  };


handleNavigation = (route: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), route);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };
  handleTabChange = (tab: string) => {
    this.setState({ selectedTab: tab });
  };

  getTabContent = () => {
    const { productData, selectedTab } = this.state;
    if (!productData) return "";
    switch (selectedTab) {
      case "Description":
        return productData.description;
      case "Product Details":
        return productData.detail;
      case "Additional Information":
        return productData.additional_information;
      default:
        return productData.description;
    }
  };
 
  handlePreviousPage = async () => {
    if (!this.state.isFirstPage) {
      const newPage = this.state.currentPage - 1;
      await this.handlePopularProduct(newPage);
    }
  };
  
  handleNextPage = async () => {
    if (!this.state.isLastPage) {
      const newPage = this.state.currentPage + 1;
      await this.handlePopularProduct(newPage);
    }
  };
  showProduct = async (productId:number) =>{
    this.setState({ productid: productId }, () => {
      this.handleShowProduct(this.state.productid); 
  });
  }
  navigateToShop = async ()=>{
    const navigateToUserProfile = new Message(getName(MessageEnum.NavigationMessage));
    navigateToUserProfile.addData(getName(MessageEnum.NavigationTargetMessage), "NavigationMenu");
    navigateToUserProfile.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigateToUserProfile);
  }

  handleProductNavigation = (productId: number) => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), 'NavigationMenu');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), productId);
    this.send(message);
}
handleProductNavigationcheckout = (productId: number) => {
  const message: Message = new Message(getName(MessageEnum.NavigationMessage));
  message.addData(getName(MessageEnum.NavigationTargetMessage), 'OrderSummary');
  message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  message.addData(getName(MessageEnum.NavigationScreenNameMessage), productId);
  this.send(message);
}
increment() {
  this.setState(prevState => ({
    count: prevState.count + 1
  }));
}

decrement() {
  if (this.state.count > 1) {
    this.setState(prevState => ({
      count: prevState.count - 1
    }));
  }
};
  // Customizable Area End
}
