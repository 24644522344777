import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  TextField,
  Card,
  CardMedia,
  CardContent,
  Grid,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import WebHeader from "../../../blocks/landingpage/src/WebHeader.web";
import WebFooter from "../../../components/src/Footer.web";
import Pagination from "@material-ui/lab/Pagination";
import {search} from "./assets"
// Customizable Area End

import NavigationMenuController, {
  Props,
  configJSON,
} from "./NavigationMenuController";

export default class NavigationMenu extends NavigationMenuController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
   const { currentPage, totalProducts,categoryName } = this.state;
  const startProduct = (currentPage - 1) * this.state.fixedproductsPerPage + 1;
  const endProduct = Math.min(currentPage * this.state.fixedproductsPerPage, totalProducts);
    return (
      <ThemeProvider theme={theme}>
        <WebHeader navigation={this.props.navigation} />
        <Box>
        {this.state.bannerImage && (
            <img
              src={this.state.bannerImage}
              alt="Banner"
              style={{ width: "100%", height: "364px" }}
            />
          )}
        </Box>
        <Box style={webStyle.headingShopBox}>
          <Typography style={webStyle.headingShopText}>Shop</Typography>
          </Box> 
          <Box style={webStyle.seacrchBox}>
          <img src={search} style={{ height: '24px', marginRight: '5px' }} />
          <TextField
            placeholder="Search products"
            InputProps={{
              disableUnderline: true,
              
              style: {
                fontFamily:'Lato',
                fontSize:'18px',
                fontWeight:600,
                border: 'none',
                width: '100%',
                height: '100%',
              },
            }} 
            
          />
        </Box>
        <Box style={{display:"flex",alignSelf:"center"}}> {this.state.categories.map((category) => (
            <Button
              key={category.id}
              onClick={() => this.handleCategoryClick(category.attributes.id,category.attributes.name,this.state.currentPage)}
              style={{...webStyle.categoryButton,textTransform:"none"}}
              variant="outlined"
              data-test-id="category"
            >
              {category.attributes.name}
            </Button>
          )
          )}</Box>
         
           <Box >
           <Box style={{margin:"10px 53px 10px 70px",display:"flex",justifyContent:"space-between"}}>
            <Typography style={{ color: "#0F172A", fontSize: "24px", fontWeight: "700", fontFamily: "Quattrocento" }}>
            {categoryName }
          </Typography>
          <Typography style={{color:"#64748B",fontSize:"20px",fontFamily:"Quattrocento",fontWeight:700}}>
            {startProduct}-{endProduct} of {totalProducts} results
          </Typography>
          </Box>
          <Grid container style={{display:"flex",justifyContent:"space-around",width:"100%",marginLeft:"70px",marginRight:"50px"}}>
            {this.state.products.map((product) => (          
              <Grid item xs={12} sm={6} md={3} key={product.id} >
                <Card style={{ marginBottom: '30px',width: "75%", transform: "scale(1.0)",boxShadow: "none" }}  onClick={() => this.handleProductNavigation(product.id)}  >
                  <CardMedia
                    component="img"
                    alt={product.attributes.product_name}
                    height="140"
                    image={product.attributes.main_image}
                    title={product.attributes.product_name}
                    style={{borderRadius:"10px",height:"200px"}}
                  />
                  <CardContent style={{padding:"10px "}}>
                    <Typography gutterBottom style={{color:"#000000",fontFamily:"Lato",fontSize:"20px",fontWeight:"600"}}>
                      {product.attributes.product_name}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      style={{
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        WebkitLineClamp: 2,
                        fontSize:"16px",
                        fontWeight:""
                      }}
                    >
                      {product.attributes.description}
                    </Typography>
                   <Box style={{display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center"}}> <Typography variant="h6">
                      ${product.attributes.price}
                    </Typography>
                    <Button style={{...webStyle.categoryButton2,textTransform:"none"}}
                     variant="outlined"   onClick={() => this.handleProductNavigation(product.id)}  >
                      Buy Now
                    </Button>
                    </Box>
                  </CardContent>
                  {/* <CardActions>                 
                  </CardActions> */}
                </Card>
              </Grid>
            ))}
          </Grid>
          <Pagination
            count={Math.ceil(this.state.totalProducts / this.state.fixedproductsPerPage)}
            page={this.state.currentPage}
            onChange={this.handlePageChange}
            style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' ,marginBottom:"30px"}}
            data-test-id ="productNavigate"
          />
        </Box>
        <WebFooter navigation={this.handleNavigation}   data-test-id="HandelFooter"/>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const webStyle = {
  userProfileWrapper: {
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 30,
  },
  userProfile: {
    width: 100,
  },
  userDesignation: {
    fontSize: 14,
    color: "#ccc",
  },
  logout: {
    color: "#2196F3",
    marginTop: 15,
    marginBottom: 5,
  },
  drawerItemIcon: {
    width: 20,
    selfAlign: "center",
    marginLeft: 7,
  },
  headingShopText: {
    fontFamily:"Quattrocento",
    fontWeight:"700",
    fontSize:"24px",
    },
  headingShopBox: {
   display:"flex",
   justifyContent: "center",
   marginTop:"24px",
   marginBottom:"15px"
    },
  seacrchBox: {
    display: "flex",
    alignItems: "center", 
    height: "57px",
    borderRadius: "8px  solid #FFFFFF",
    boxShadow: "0px 8px 8px #00000014", 
    marginBottom:"44px",
    width:"26%",
    paddingLeft:"13px",
     alignSelf:"center",
    },
  categoryButton:{
    height:"48px",
    borderRadius:"100px",
    width:"140px",
    borderColor:"#9176C4",
    margin:"10px",
  },
  categoryButton2:{
    height:"48px",
    borderRadius:"100px",
    width:"100px",
    borderColor:"#9176C4",
    margin:"10px",
  },

};
// Customizable Area End
