import React from "react";

import {
  Container,
  Box,
  Typography,
  Link,
  Breadcrumbs,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import NavigateNextIcon from '@material-ui/icons/NavigateNext'; 
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import WebHeader from "../../landingpage/src/WebHeader.web";
import WebFooter from "../../../components/src/Footer.web";
// src/packages/components/src/webHeader.web.tsx"
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import TermsandconditionsController, {
  Props,
  configJSON,
} from "./TermsandconditionsController";
import moment from "moment";

export default class Termsandconditions extends TermsandconditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { termsOfUse } = this.state;
    const lastUpdatedDate = termsOfUse ?.terms_of_use?.updated_at 
    ? moment(termsOfUse.terms_of_use.updated_at).format('DD MMMM YYYY') 
    : '';
    const termsDescription = termsOfUse ? termsOfUse.terms_of_use.description: 'Loading...';

    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Box>
        <WebHeader navigation={this.props.navigation} /> 
        <Container maxWidth={"xl"}>  
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" style={webStyle.breadBox}>
                <Link  href="/" style={{color:"#475569",fontFamily:"Lato",fontSize:"16px",fontWeight:500}}>
                  Legal
                </Link>

                <Typography color="textPrimary" style={{color:"#0F172A",fontFamily:"Lato",fontSize:"16px",fontWeight:600}}>Terms of Use</Typography>
              </Breadcrumbs>       
          <Box>
          <Box className="terms-of-use">
            <Box style={{marginTop:"4%"}}><Typography  style={{color:"#0F172A",fontSize:"40px",fontWeight:700,fontFamily:"Quattrocento"}}  align="center" >
                Terms of Use
              </Typography>
              </Box>
              <Typography  align="center"style={webStyle.dateBox}>
                <em>Last updated: {lastUpdatedDate}</em>
              </Typography>
              <Box dangerouslySetInnerHTML={{ __html: termsDescription }} />
            </Box>
          </Box>
        </Container>
        <WebFooter navigation={this.handleLogInNavigation} 
        data-test-id="HandelHeader"/>
        </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    height: "100px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    height: "45px",
    marginTop: "40px",
    border: "none",
    width: "100%",
    backgroundColor: "rgb(98, 0, 238)",
  },
  mainWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
    fontFamily: "Roboto-Medium",
  },
  dateBox:{
    color:"#1E293B",
    fontSize:"24px",
    fontWeight:700,
    fontFamily:"Lato",
    marginBottom:"40px"
   },
   breadBox:{
    marginTop:"35px",
   }
};
// Customizable Area End
