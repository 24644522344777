import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  drawerOpen:boolean;
  activeMenu:any;
  PrivacyPolicy:any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PrivacypolicyController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  fetchPrivacypolicyCallId:string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
   
    // Customizable Area Start
    this.fetchPrivacypolicy = this.fetchPrivacypolicy.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      drawerOpen:false,
      activeMenu:0,
      PrivacyPolicy: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
  
    // Customizable Area End
  }

  // async receive(from: string, message: Message) {
  //   // runEngine.debugLog("Message Recived", message);

  //   // if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
  //   //   let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

  //   //   this.showAlert(
  //   //     "Change Value",
  //   //     "From: " + this.state.txtSavedValue + " To: " + value
  //   //   );

  //   //   this.setState({ txtSavedValue: value });
  //   // }

    // Customizable Area Start
  
   // Customizable Area End
  // }


  // Customizable Area Start
 
  async componentDidMount() {
    this.fetchPrivacypolicy();
  }
  async receive(from: string, message: Message) {
    
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
         
      if (apiRequestCallId === this.fetchPrivacypolicyCallId) {
        
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

        if (responseJson && responseJson.privacy_policy) {
          this.setState({
            PrivacyPolicy: responseJson,
          });
        } 
      }
    }
  }

  async fetchPrivacypolicy() {
    const header = {
      "Content-Type": "application/json",
    };
  
    const fetchPrivacyMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.fetchPrivacypolicyCallId = fetchPrivacyMsg.messageId; 
  
    fetchPrivacyMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_terms_and_conditions/privacy_policies"
    );
  
    fetchPrivacyMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    fetchPrivacyMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(fetchPrivacyMsg.id, fetchPrivacyMsg);
  }
  

  // below code is for  header 
  handleLogInNavigation = (path: any) => {
    const toNavigate: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), path);
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
  };

  // Customizable Area End
}
