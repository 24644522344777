import React from "react";

// Customizable Area Start
import { 
    Box, 
    Button, 
    FormHelperText, 
    Grid,
    Snackbar, 
    TextField, 
    Typography 
} from "@material-ui/core";
import { 
    createTheme, 
    styled, 
    ThemeProvider,
} from "@material-ui/core/styles";
import Alert from '@material-ui/lab/Alert';
import { backimage, earth, logo } from "../../signuplogin/src/assets";
import { Form, Formik } from "formik";
import GenericModal from "../../../components/src/GenericModal";

const theme = createTheme({
    overrides: {
      MuiTypography: {
        h1: {
            color: "#0F172A", 
            fontFamily: "Quattrocento", 
            fontSize: "24px", 
            fontWeight: 700,
            lineHeight: "32px",
        },
        h2: {
            color: "#475569", 
            fontFamily: "Lato", 
            fontSize: "20px", 
            fontWeight: 500,
        },
        h5: {
            marginBottom: "15px", 
            color: "#0F172A", 
            fontFamily: "Quattrocento", 
            fontSize: "20px", 
            fontWeight: 700
        },
        h6: {
            marginBottom: "20px", 
            color: "#64748B", 
            fontFamily: "Lato", 
            fontSize: '16px', 
            fontWeight: 400
        },
      },
      MuiButton: {
        text: { 
            color: "#9176C4", 
            textTransform: "none", 
            fontFamily: "Lato", 
            fontSize: '16px', 
            fontWeight: 500,   
            paddingTop: "0",
        },
      }
    },
  });

// Customizable Area End

import ForgotPasswordController, {
  Props,
  configJSON,
} from "./ForgotPasswordController.web";

export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
        // Customizable Area Start
        <>
            <ThemeProvider theme={theme}>
                <Wrapper>
                    <Grid container className="container">
                        <Grid item xs={12} md={6}>
                            <Box>
                                <img src={backimage} alt="Delegate Mee" className="image" width={'100%'} />
                                <Box className="welcomeSection">
                                    <Typography variant="h1">
                                        {configJSON.welcomeText}
                                    </Typography>
                                    <Typography variant="h2">
                                        {configJSON.welcomeBodytext}
                                    </Typography>
                                </Box>
                                <Box className="earthImgWrapper">
                                    <img src={earth} alt="Earth Globe" />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6} className="formGrid">
                            <Box className="formWrapper">
                                <Box style={{ marginBottom: "20px" }}>
                                    <img src={logo} alt="logo" />
                                </Box>
                                <Box className="contentWrap">
                                    <Typography variant="h5" data-test-id="forgotPasswordTitle">{configJSON.forgotPasswordText}</Typography>
                                    <Typography variant="h6">{configJSON.forgotPasswordBodyText}</Typography>
                                </Box>
                                <Formik
                                    initialValues={{
                                        email: this.state.email
                                    }}
                                    validationSchema={this.validationSchema}
                                    onSubmit={(values) => {
                                        this.handleSubmit(values)
                                    }}
                                    enableReinitialize={true}
                                    data-test-id="forgotPasswordForm"
                                >
                                    {({ values, errors, touched, setFieldValue }) => (
                                        <Form>
                                            <label className="label">{configJSON.emailFieldLabel}<span className="span">*</span></label>
                                            <Box className="emailFieldWrapper">
                                                <TextField
                                                    fullWidth
                                                    name="email"
                                                    placeholder="example@gmail.com"
                                                    data-test-id="txtInput"
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        style: {
                                                            border: 'none',
                                                            height: '',
                                                            paddingLeft: "10px",
                                                            width: "100%"
                                                        },
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: false,
                                                    }}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                        setFieldValue("email", e.target.value)
                                                    }
                                                    value={values.email}
                                                />
                                            </Box>
                                            {touched.email && errors.email && (
                                                <FormHelperText error style={{ color: "red" }}>
                                                    * {errors.email}
                                                </FormHelperText>
                                            )}
                                            <Box display="flex" justifyContent="center">
                                                <Button fullWidth variant="contained" className="button" type="submit" data-test-id="sendEmailBtn" >
                                                    {configJSON.sendEmailBtnText}
                                                </Button>
                                            </Box>
                                        </Form>
                                    )}
                                </Formik>
                                <Typography variant="h6" align="center" style={{ color: "#0F172A", marginTop: "25px" }}>
                                    {configJSON.rememberPasswordText} 
                                    <Button 
                                        variant="text" 
                                        data-test-id="loginLink" 
                                        onClick={() => this.handleNavigation("Signuplogin")}
                                        style={{ textDecoration: 'underline',  textUnderlineOffset: '4px' }}
                                    >
                                        {configJSON.loginLinkText}
                                    </Button>
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Wrapper>
            </ThemeProvider>
            <GenericModal open={this.state.openDialog === "emailSent"} dataTest="email-sent-modal" onClose={this.handleClose}>
            <ThemeProvider theme={theme}>
                <DialogWrapper>
                    <Box>
                        <Typography align="center" variant="h1" data-test-id="emailSentModalTitle">Email Sent</Typography>
                        <Typography align="center" variant="h6" style={{ color: "#0F172A", fontWeight: 500 }}>
                            We've sent a recovery link to your email at <span className="emailSpan">"{this.state.email}"</span>. If you don't see the email, kindly check your spam folder.
                        </Typography>
                    </Box>
                    <Box className="btnWrapper">
                        <Button 
                            className="outlinedBtn" 
                            variant="outlined"
                            onClick={() => this.handleClose()}
                        >
                                {configJSON.ReEnterEmailBtnText}
                        </Button>
                        <Button
                        data-test-id="resentLinkBtn" 
                        className="containedBtn" 
                        variant="contained"
                        onClick={() => this.reSendLink()}
                        >
                            {configJSON.resentLinkBtnText}
                        </Button>
                    </Box>
                </DialogWrapper>
            </ThemeProvider>
            </GenericModal>
            <GenericModal open={this.state.openDialog === "resentLink"} dataTest="resent-link" onClose={this.handleClose}>
            <ThemeProvider theme={theme}>
                <DialogWrapper>
                    <Box>
                        <Typography align="center" variant="h1" data-test-id="resentLinkTitle">Resent Link!</Typography>
                            <Typography align="center" variant="h6" style={{ color: "#0F172A", fontWeight: 500 }}>
                                We have resent the recovery link to your email address at <span className="emailSpan">"{this.state.email}"</span>. Please click on the link to reset your password.
                                If you don’t see the email, kindly check your spam folder.
                            </Typography>
                    </Box>
                    <Box className="btnWrapper">
                        <Button 
                            fullWidth 
                            style={{width: "100%"}} 
                            className="containedBtn" 
                            variant="contained"
                            onClick={() => this.handleClose()}>
                                {configJSON.okayBtnText}
                            </Button>
                    </Box>
                </DialogWrapper>
            </ThemeProvider>
            </GenericModal>
            {this.state.errorMessage && (
                <Snackbar
                    open={this.state.openSnackbar}
                    autoHideDuration={4000}
                    onClose={this.handleCloseSnackbar}
                    data-test-id="errorSnackbar">
                    <Alert onClose={this.handleCloseSnackbar} severity="error">
                        {this.state.errorMessage}
                    </Alert>
                </Snackbar>
        )}
        </>
        // Customizable Area End
        );
  }
}

// Customizable Area Start
const Wrapper = styled('div')(({ theme }) => ({
    "& .container": {
        minHeight: '100vh',
      },
    "& .image": {
        Width: '100%',
        height: '660',
        margin:2,
        padding:2
    },
    "& .earthImgWrapper": {
        position: 'absolute',
        top: '45%',
        left: '25%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center', 
        justifyContent: "center",
    },
    "& .welcomeSection": {
        marginBottom: '150px', 
        position: 'absolute', 
        top: '80%', 
        left: '50%', 
        transform: 'translate(-100%, -50%)',
        textAlign: 'center', 
        wordWrap: "break-word",
        padding: 70
    },
    "& .contentWrap": {
        width: "327px",
    },
    "& .formWrapper": {
        width: '405px',
        margin: '0 auto',
    },
    "& .formGrid": {
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        flexDirection:"column",
        backgroundColor:"fff",
    },
    "& .label": {
        color: "#334155", 
        fontFamily: "Lato", 
        fontSize: "14px", 
        fontWeight: 400,
        marginBottom: "10px",
    },
    "& .span": {
        color: "red", 
        marginTop: "5px",
    },
    "& .emailFieldWrapper": {
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        justifyContent:'space-around',
        border: '1px solid #C5CBC9', 
        height: '48px', 
        padding: '10px, 24px, 10px, 24px', 
        borderRadius: '8px', 
        marginBottom: '16px',
        marginTop: '8px',
    },
    "& .button": {
        width: "372px",
        height: "48px",
        marginTop: "72px",
        marginBottom: 0,
        backgroundColor: "#A190C2",
        color: "#fff",
        borderRadius: "46px",
        textTransform: "none",
        fontSize: "16px",
        fontFamily: "Lato",
    }
}));

const DialogWrapper = styled('div')(({ theme }) => ({
    "& .alignCenter": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "8px",
    },
    "& .btnWrapper": {
        display: "flex",
        justifyContent: "center",
        gap: "8px",
    },
    "& .emailSpan": {
        fontColor: "Black",
        fontWeight: 700,
    },
    "& .outlinedBtn": {
        width: "192px",
        heigh: "56px",
        color: "#9176C4",
        borderRadius: "100px",
        textTransform: "none",
        padding: "16px",
        fontSize: "16px",
        fontWeight: 600,
        fontFamily: "Lato",
    },
    "& .containedBtn": {
        width: "192px",
        heigh: "56px",
        backgroundColor: "#9176C4",
        color: "#FFFFFF",
        borderRadius: "100px",
        textTransform: "none",
        padding: "16px",
        fontSize: "16px",
        fontWeight: 600,
        fontFamily: "Lato",
    },
    "& .rightTickImg": {
        width: "72px",
        height: "72px",
    },
    [theme.breakpoints.down('xs')]: {
        "& .btnWrapper": {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
        }
      }
}));
// Customizable Area End
