import React from "react";
// Customizable Area Start
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import ShowProductController, {
  Props,
} from "./ShowProductController.web";
import NavigateNextIcon from '@material-ui/icons/NavigateNext'; 
import { Box, Grid, Typography, Button,  CardMedia, CardContent, Card, Breadcrumbs } from "@material-ui/core";
import WebHeader from "../../landingpage/src/WebHeader.web";
import WebFooter from "../../../components/src/Footer.web";
const theme = createTheme({
  overrides: {
    MuiTypography: {
      h1: {
        color: "#0F172A",
        fontFamily: "Lato",
        fontSize: "42px",
        fontWeight: 600,
        lineHeight: "54px",
      },
      h2: {
        color: "#0F172A",
        fontFamily: "Lato",
        fontSize: "38px",
        fontWeight: 700,
        lineHeight: "54px",
      },
      h3: {
        color: "#000000",
        fontFamily: "Lato",
        fontSize: "32px",
        fontWeight: 700,
        lineHeight: "54px",
      },
      h6: {
        fontFamily: "Lato",
        fontSize: "20px",
        fontWeight: 700,
        lineHeight: "28px",
      },
      subtitle1: {
        fontFamily: "Lato",
        fontSize: "22px",
        fontWeight: 600,
        lineHeight: "54px",
      },
      body1: {
        fontFamily: "Lato",
        fontSize: "24px",
        fontWeight: 400,
        lineHeight: "40px",
      },
      body2: {
        fontFamily: "Lato",
        fontSize: "18px",
        fontWeight: 400,
        lineHeight: "24px",
      },
    },
  },
});
// Customizable Area End

export default class ShowProduct extends ShowProductController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
     
    const { productData, selectedTab,isFirstPage,isLastPage } = this.state;
    return (
      <ThemeProvider theme={theme}>
        <Wrapper>
          <WebHeader navigation={this.props.navigation} />
           
          <Box className="container">
           
            {productData && (
              <Box>
                <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className="breadCrumb">
              <Typography   style={{color:"#475569",fontFamily:"Lato",fontSize:"16px",fontWeight:500}} onClick={() => this.navigateToShop()} data-test-id = "shopBtn">
               Shop
              </Typography>

              <Typography color="textPrimary" style={{color:"#475569",fontFamily:"Lato",fontSize:"16px",fontWeight:600}} onClick={() => this.handleProductNavigation(productData.category_id)} data-test-id = "productNavigate">{productData.category_name}</Typography>
              <Typography color="textPrimary" style={{color:"#0F172A",fontFamily:"Lato",fontSize:"16px",fontWeight:600}}>{productData.product_name}</Typography>

            </Breadcrumbs> 
              <Grid  className="mainGrid" >
                <Grid item xl={6} className="imageSection">
                  <Box className="mainImageCard">
                    <CardMedia
                      component="img"
                      image={productData.main_image}
                      alt={productData.product_name}
                      style={{borderRadius:"8px",height:"512px"}}
                    />
                  </Box>
                  <Box className="thumbnailImages">
                    {productData.other_images.map((img: any, index: number) => (
                      <Box key={index} className="thumbnailCard">
                        <CardMedia component="img" image={img.url}  style={{borderRadius:"8px"}}/>
                      </Box>
                    ))}
                  </Box>
                </Grid>
                <Grid item xl={6} className="detailsSection">
                  <Typography variant="h1">{productData.product_name}</Typography>
                  <Typography variant="body1">${productData.price.toFixed(2)}</Typography>
                  <Box className="quantitySection">
                  <Typography variant="body2">Quantity:</Typography>
                   <button className="inc" onClick={this.increment} data-test-id = "increment">+</button> 
                   <Box style={{width:"40px",backgroundColor:"#F5F5F5",justifyContent:"center",paddingLeft:"25px"}}>
                   <Typography > {this.state.count}</Typography>
                   </Box>       
                   <button className="dec" onClick={this.decrement} data-test-id = "decrement">-</button>
                   
                  </Box>
                  <Box className="desc">
                  <Box className="headingSection">
                  {["Description", "Product Details", "Additional Information"].map((tab) => (
                    <Typography
                      key={tab}
                      variant="body2"
                      className={selectedTab === tab ? "activeTab" : "tab"}
                      onClick={() => this.handleTabChange(tab)}
                      data-test-id="btnChange"
                    >
                      {tab}
                    </Typography>
                  ))}
                </Box>
                <Typography variant="body2" className="descriptionText">
                  {this.getTabContent()}
                </Typography>
                </Box>
                <Box style={{marginTop:"9%",alignSelf:"center"}}>
                  <Button variant="contained" className="buyNowButton" style={{textTransform:"none"}} onClick={() =>this.handleProductNavigationcheckout(productData.id)} data-test-id="buyBtn">
                    Buy Now
                  </Button>
                  </Box>
                </Grid>
              </Grid>
              </Box>
            )}
          </Box>
          <Box>
            <Box style={{marginLeft:"74px",marginRight:"86px",marginBottom:"10px"}} className="paginationBox">
                <Typography style={{color:"#0F172A",fontWeight:600,fontSize:"20px",fontFamily:"Lato"}}>Popular Products</Typography>
                <Box className="paginationSection">
                <Button variant="text" onClick={this.handlePreviousPage} disabled={isFirstPage} className="paginationButton" data-test-id="prevButton">
              {"<"}
            </Button>
            <Button onClick={this.handleNextPage} disabled={isLastPage} className="paginationButton" data-test-id="nextButton">
              {">"}
            </Button>
          </Box>

                </Box>
          <Grid container style={{display:"flex",justifyContent:"space-around",width:"100%",marginLeft:"80px",marginRight:"80px"}}>
            {this.state.products.map((product) => (          
              <Grid item xs={12} sm={6} md={3} key={product.id} >
                <Card style={{ marginBottom: '20px', marginLeft: "-15px", width: "75%", transform: "scale(1.0)", 
                  marginRight: "15px", boxShadow: "none"}} onClick={()=> this.showProduct(product.id)} data-test-id="singleuser" >
                  <CardMedia
                    component="img"
                    alt={product.attributes.product_name}
                    image={product.attributes.main_image}
                    title={product.attributes.product_name}
                    style={{borderRadius:"10px",height:"200px"}}
                    height="140"
                  />
                  <CardContent style={{padding:"10px "}}>
                    <Typography gutterBottom style={{color:"#000000",fontFamily:"Lato",fontSize:"20px",fontWeight:"600"}}>
                      {product.attributes.product_name}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      style={{
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitLineClamp: 2,
                        fontSize:"16px",
                        fontWeight:"",
                        WebkitBoxOrient: 'vertical',
                        
                      }}
                    >
                      {product.attributes.description}
                    </Typography>
                   <Box style={{display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center"}}> <Typography variant="h6">
                      ${product.attributes.price}
                    </Typography>
                    <Button style={{textTransform:"none"}}
                     variant="outlined" className="cardButton"  onClick={()=> this.showProduct(product.id)} data-test-id="singleuser">
                      Buy Now
                    </Button>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
          </Box>
          
          <WebFooter navigation={this.handleNavigation}  data-test-id="HandelFooter"/>
        </Wrapper>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const Wrapper = styled("div")(({ theme }) => ({
    "& .container": {
        padding: "20px 42px",
        backgroundColor: "#FAFFF3",
      },
      "& .mainGrid": {
        display: "flex",
        justifyContent: "center",
        flexDirection:"row",
      },
      "& .imageSection, & .detailsSection": {
        width: "50%", 
        padding: "15px",
        boxSizing: "border-box",
      },
      "& .imageSection": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderRadius:'8px'
      },
      "& .mainImageCard": {
        // maxWidth: "98%",
        width:"90%",
        marginBottom: "5px",
        height:"512px",
        borderRadius:"8px"
      },
      "& .thumbnailImages": {
        display: "flex",
        justifyContent: "space-between",
        borderRadius:"15px"
      },
      "& .thumbnailCard": {
        width: "138px",
        height: "150px",
        padding:"10px",
       
      },
      "& .detailsSection": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        padding:"17px",
        paddingRight:"25px"
      },
      "& .quantitySection": {
        display: "flex",
        alignItems: "center",
        margin: "20px 0",
      },
      "& .quantityInput": {
        width: "50px",
        marginLeft: "10px",
      },
      "& .buyNowButton": {
        backgroundColor: "#A190C2",
        color: "#FFFFFF",
        padding: "10px 20px",
        borderRadius:"100px",
        height:"44px",
        width:"300px",
        alignSelf:"center",
        justifySelf:"end"
      },
      "& .headingSection": {
        display: "flex",
        justifyContent: "space-between",
        borderBottom: "1px solid #e0e0e0",
        marginBottom: "20px",
      },
      "& .tab, & .activeTab": {
        padding: "10px 20px",
        cursor: "pointer",
        flexGrow: 1,
        textAlign: "center",
      },
      "& .activeTab": {
        borderBottom: "2px solid #6B46C1",
        color: "#6B46C1",
      },
      "& .tab": {
        color: "#64748B",
      },
      "& .descriptionText": {
        // marginTop: "20px",
        fontSize: "18px",
        lineHeight: "26px",
        color:"#1E293B",
        fontFamily:"Lato",
        fontWeight:400,
        Paragraph:"12px",
        paddingRight:"45px",
        height:"300px"
      },
      "& .desc":{
         marginRight:"30px"
      },
      "& .cardButton":{
        height:"48px",
        borderRadius:"100px",
        width:"100px",
        borderColor:"#9176C4",
        margin:"10px",
      },"& .paginationSection": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        margin:"4px -40px"
      },
      "& .paginationButton": {
        minWidth: "30px",
        minHeight: "30px",
        borderRadius: "50%",
        backgroundColor: "#f8faff",
        color: "#0F172A",
        "&:disabled": {
          color: "#9CA3AF",
        },
      },
      "& .paginationText": {
        color: "#0F172A",
        fontWeight: 600,
        fontSize: "20px",
        fontFamily: "Lato",
      },
      "& .paginationBox":{
         display:"flex",
         justifyContent:"space-between",
         marginTop:"20px"
        
      },
      "& .inc":{
        backgroundColor:"#F5F5F5",
        padding:"6px",
        margin:"10px"
      },
      "& .dec":{
        backgroundColor:"#F5F5F5",
        padding:"6px",
        margin:"10px",
        paddingLeft:"10px",
        paddingRight:"10px"
      },
      "& .breadCrumb":{
        paddingLeft:"55px",
        paddingTop:"15px"
      },

  // Reasons to choose section
//     [theme.breakpoints.down(500)]: {
//      "& .descriptionText": {
//         // marginTop: "20px",
//         fontSize: "18px",
//         lineHeight: "26px",
//         color:"#1E293B",
//         fontFamily:"Lato",
//         fontWeight:400,
//         Paragraph:"12px",
//         paddingRight:"5px"
//       },
    
//    },
}));
// Customizable Area End
