Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "signuplogin";
exports.labelBodyText = "signuplogin Body";

exports.btnExampleTitle = "CLICK ME";
exports.loginApiContentType = "application/json";

exports.loginAPiMethod = "POST";
exports.loginAPiEndPoint = "bx_block_login/logins"

exports.signUpApiContentType = "application/json";
exports.signupAPiMethod = "POST";
exports.signupAPiEndPoint = "bx_block_signuplogin/signup"

exports.emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

exports.signUpText = "Sign Up";
exports.signUpTextSubTitle = "Let’s get you started!";
exports.signUpBtnText = "Signup";

exports.welcomeBackText = "Welcome Back!";
exports.loginSubtitle = "Please enter your login details to continue.";
exports.loginBtnText = "Login";

exports.reEnterBtnText = "Re-enter Email";
exports.resendLinkBtnText = "Resend Link";
exports.verifyEmailText = "Verify Email";
// Customizable Area End
