import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { userProfile } from "./assets";
import { Linking } from "react-native";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  drawerContent?: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  webDrawer: boolean;
  token: any;
  drawerItems: any;
  bannerImage: string;
  categories: any[];
  products: any[];
  selectedCategory: string;
  currentPage: number;
  productsPerPage: number;
  fixedproductsPerPage:number;
  totalProducts: number;
  categoryName:string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NavigationMenuController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetDataCallId: string = "";
  bannerCallId: string = "";
  categoriesCallId: string = "";
  productsCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      webDrawer: false,
      token: "",
      drawerItems: [],
      bannerImage:"",
      categories: [],
      products: [],
      selectedCategory: "",
      currentPage: 1,
      fixedproductsPerPage: 8,
      productsPerPage: 8,
      totalProducts: 0,
      categoryName:"",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token }, () => {
        this.getMenuItems();
      });
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiGetDataCallId !== "" &&
      this.apiGetDataCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson) {
        this.setState({ drawerItems: responseJson });
      } else {
        this.parseApiErrorResponse(responseJson);
      }
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
         
      if (apiRequestCallId === this.bannerCallId) {
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        
        if (responseJson && responseJson.data && responseJson.data.attributes) {
          this.setState({
            bannerImage: responseJson.data.attributes.banner_image,
          });
        } 
      }
      if (apiRequestCallId === this.categoriesCallId) {
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        this.setState({ categories: responseJson.data ,categoryName:responseJson.data[0].attributes.name,selectedCategory:responseJson.data[0].id})
      }  
      if (apiRequestCallId === this.productsCallId) {
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
          this.setState({products:responseJson.data,totalProducts: responseJson.meta.totel_products,
            productsPerPage: responseJson.meta.product_per_page,
            })
        }
        
       
    } 
    // Customizable Area End
  }

  // Customizable Area Start
  toggleDrawer = (event: any) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    this.setState({ webDrawer: !this.state.webDrawer });
  };

  onPressMenuItem = (menuItem: any) => {
    let path = menuItem.url;

    var tarea_regex = /^(http|https)/;
    if (tarea_regex.test(String(path).toLowerCase())) {
      if (this.isPlatformWeb()) {
        window.open(path);
      } else {
        Linking.openURL(path);
      }
    } else {
      const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), path);
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg);
    }
  };
  
  userProfileProps = {
    source: userProfile,
  };

  async componentDidMount() {
    super.componentDidMount();
    this.fetchBannerData();
    this.fetchCategories();
    const productId = this.props.navigation.getParam("navigationBarTitleText"); 

    if(productId && productId !== 'undefined'){
      this.setState({ selectedCategory: productId }, () => {
        this.fetchProducts(this.state.selectedCategory,this.state.categoryName,this.state.currentPage); 
    });
    } else {
      this.fetchProducts(this.state.selectedCategory, this.state.categoryName,this.state.currentPage);
    }


 
 
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  getMenuItems = async () => {
    let token = this.state.token;

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMenuItemsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleNavigation = (route: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), route);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  apiCall = async (data: any) => {
    const token = await getStorageData("token");
    const { contentType, method, endPoint, body, type } = data;
    const header = { "Content-Type": contentType, token };
    const request = new Message(getName(MessageEnum.RestAPIRequestMessage));
    request.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    request.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    request.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    body && type != "formData"
      ? request.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body)
        )
      : request.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);
    runEngine.sendMessage(request.id, request);
    return request.messageId;
  };

  fetchBannerData = async () => {
    this.bannerCallId = await this.apiCall({
      method: "GET",
      endPoint: "/bx_block_admin/banners",
      contentType: "application/json",
    });
  }
  fetchCategories = async () => {
    this.categoriesCallId = await this.apiCall({
      method: "GET",
      endPoint: "/bx_block_categories/categories",
      contentType: "application/json",
    });
  };

  fetchProducts = async (categoryId: string,categoryName:string,currentPage: number) => {
    this.productsCallId = await this.apiCall({
      method: "GET",
      endPoint: `/bx_block_categories/products?category_id=${categoryId}&category_name=${categoryName}&page=${currentPage}`,
      contentType: "application/json",
    });
  };

  handleCategoryClick = (categoryId: string,categoryNamee:string,page:number) => {
    this.setState({ selectedCategory: categoryId,categoryName:categoryNamee, currentPage:page}, () => {
      this.fetchProducts(this.state.selectedCategory,this.state.categoryName,this.state.currentPage);
    });
  };
 
  handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    this.setState({ currentPage: page }, () => {
      this.fetchProducts(this.state.selectedCategory, this.state.categoryName,this.state.currentPage);
    });
  };
  handleProductNavigation = (productId: number) => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), 'ShowProduct');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), productId);
    this.send(message);
   
}

  // Customizable Area End
}
