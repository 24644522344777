import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { sendAPIRequest } from "../../../components/src/Utils";
export interface IAllAboutData {
  id: number;
  heading: string;
  description: string;
  image: string;
  background_image: string;
}

export interface IAllAboutApiResponse {
  id: string;
  type: string;
  attributes: IAllAboutData;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  aboutData: IAllAboutData[];
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      aboutData: [],
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJSON = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    this.apiSuccessCallBackController(apiRequestCallId, responseJSON);
    // Customizable Area End
  }

  // Customizable Area Start
  callAllAboutsApiId: string = "";

  async componentDidMount() {
    super.componentDidMount();
    this.getAllAboutsDataList();
  }

  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJSON: Record<string, unknown>
  ) => {
    const successCallbackMap = {
      [this.callAllAboutsApiId]: this.handlecallAllAboutsApiIdApiResponse,
    };

    if (apiRequestCallId) {
      const successCallback: (responseJSON: Record<string, unknown>) => void =
        successCallbackMap[apiRequestCallId];
      !!successCallback && successCallback(responseJSON);
    }
  };

  getAllAboutsDataList = () => {
    this.callAllAboutsApiId = sendAPIRequest(
      configJSON.getAllAboutsApiEndPoint,
      {
        method: configJSON.validationApiMethodType,
      }
    );
  };

  handlecallAllAboutsApiIdApiResponse = (
    responseJSON: Record<string, unknown>
  ) => {
    const response = responseJSON as {
      meta?: { message: string };
      data?: IAllAboutApiResponse[];
    };

    if (response.data && response.data.length > 0) {
      let aboutData: IAllAboutData[] = [];

      response.data.forEach((item) => {
        aboutData.push(item.attributes);
      });

      this.setState({ aboutData });
    }
  };

  handleNavigation = (route: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), route);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };
  // Customizable Area End
}
