import React from "react";
// Customizable Area Start
import { styled, ThemeProvider } from "@material-ui/core/styles";
import FamilyDetailsController, {
    configJSON,
    IFavouriteMemoriesAttributes,
    IFestiveMemoriesAttributes,
    Props,
} from "./FamilyDetailsController.web";
import { 
  Box, 
  Grid, 
  Typography, 
  Button,
  FormLabel,
  RadioGroup, 
  FormControlLabel,
} from "@material-ui/core";
import WebFooter from "../../../components/src/Footer.web";
import WebHeader from "../../../blocks/landingpage/src/WebHeader.web";
import AdditionalInformationSidebar from "../../../components/src/AdditionalInformationSidebar.web";
import { Field, FieldArray, Form, Formik, FormikValues } from "formik";
import { theme, StyledRadio } from "./LegalInformation.web";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CustomInput from "../../../components/src/CustomInput";
import ErrorMessage from "../../../components/src/ErrorMessage";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import MediaUpload from "../../../components/src/MediaUpload.web";

// Customizable Area End

export default class FamilyDetails extends FamilyDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderFavouriteMemoriesForm = (
    values: FormikValues,
    setFieldValue: {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
    }) => {
    return(
      <FieldArray name="favourite_memories_attributes" data-test-id="favouriteMemoriesFieldArray">
        {({ remove, push }) => (
          values.favourite_memories_attributes.map((favouriteMemoriesAttributes:IFavouriteMemoriesAttributes, index: number) => {
            return (
                <Grid item xs={12} key={index} className="formSectionBackground">
                    <Grid container spacing={2}>
                        <Grid item xs={12} className="fieldArrayHeaderWrapper">
                            <Box>
                                <Typography data-test-id="favouriteMemoriesFormTitle" variant="h6">
                                   {configJSON.favouriteMemoryFormTitle}
                                </Typography>
                                <Typography data-test-id="favouriteMemoriesSubtitle" className="memoriesSubtitle">
                                    {configJSON.memoriesSubtitle}
                                </Typography>
                            </Box>
                            {(index === 0 && values.favourite_memories_attributes.length < 3) && (
                            <Button 
                            className="addAnotherBtn"
                            data-test-id = "addAnotherMemoryBtn"
                            onClick={() => push({
                                title: "",
                                files: [],
                            })}
                            >
                            <AddCircleOutlineIcon className="circleOutlineIcon" />
                                {configJSON.addAnotherBtnText}
                            </Button>
                            )}
                            {index !== 0 && (
                            <Button 
                            data-test-id="removeAnotherMemoryBtn"
                            className="addAnotherBtn"
                            onClick={() => remove(index)}>
                            <RemoveCircleOutlineIcon className="circleOutlineIcon" />
                                {configJSON.removeBtnText}
                            </Button>)}
                        </Grid>
                        <Grid item lg={4}>
                            <FormLabel component="label">{configJSON.memoryTitleFieldText}<span style={{ color: "red" }}>*</span></FormLabel>
                            <Field
                                data-test-id="memoryTitle"
                                name="memoryTitle"
                                className="inputField"
                                size="small"
                                as={CustomInput}
                                placeholder="Enter here"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setFieldValue(`favourite_memories_attributes.${index}.title`, e.target.value)
                                }}
                                value={favouriteMemoriesAttributes.title}
                            />
                            <ErrorMessage name={`favourite_memories_attributes.${index}.title`} />
                        </Grid>
                        <Grid item lg={12}>
                            <MediaUpload
                            data-test-id="mediaUploadForFavourite"
                            files={(this.state.filesForFavouriteMemory.length > 0  && this.state.filesForFavouriteMemory[index]) ? this.state.filesForFavouriteMemory[index] : favouriteMemoriesAttributes.files}
                            onUpload={(event: React.ChangeEvent<HTMLInputElement>) => this.handleFileUpload(event, index, setFieldValue, "favourite_memories_attributes")}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            );
        })
        )}
      </FieldArray>
  )};

  renderFestiveMemoriesForm = (
    values: FormikValues,
    setFieldValue: {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
    }) => {
    return(
      <FieldArray name="festive_memories_attributes" data-test-id="festiveMemoriesFieldArray">
        {({ remove, push }) => (
          values.festive_memories_attributes.map((festiveMemoriesAttributes:IFestiveMemoriesAttributes, index: number) => {
            return (
                <Grid item xs={12} key={index} className="formSectionBackground">
                    <Grid container spacing={2}>
                        <Grid item xs={12} className="fieldArrayHeaderWrapper">
                            <Box>
                                <Typography data-test-id="festiveMemoriesFormTitle" variant="h6">
                                    {configJSON.festiveMemoryFormTitle}
                                </Typography>
                                <Typography data-test-id="festiveMemoriesFormSubtitle" className="memoriesSubtitle">
                                    {configJSON.memoriesSubtitle}
                                </Typography>
                            </Box>
                            {(index === 0 && values.festive_memories_attributes.length < 3) && (
                            <Button 
                            className="addAnotherBtn"
                            data-test-id = "addAnotherMemoryBtn"
                            onClick={() => push({
                                celebration_type: "",
                                files: [],
                            })}
                            >
                            <AddCircleOutlineIcon className="circleOutlineIcon" />
                                {configJSON.addAnotherBtnText}
                            </Button>
                            )}
                            {index !== 0 && (
                            <Button 
                            data-test-id="removeAnotherMemoryBtn"
                            className="addAnotherBtn"
                            onClick={() => remove(index)}>
                            <RemoveCircleOutlineIcon className="circleOutlineIcon" />
                                {configJSON.removeBtnText}
                            </Button>)}
                        </Grid>
                        <Grid item lg={4}>
                            <FormLabel component="label">{configJSON.celebrationTypeFieldText}<span style={{ color: "red" }}>*</span></FormLabel>
                            <Field
                                data-test-id="celebrationType"
                                name="celebrationType"
                                className="inputField"
                                size="small"
                                as={CustomInput}
                                placeholder="Enter celebration"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setFieldValue(`festive_memories_attributes.${index}.celebration_type`, e.target.value)
                                }}
                                value={festiveMemoriesAttributes.celebration_type}
                            />
                            <ErrorMessage name={`festive_memories_attributes.${index}.celebration_type`} />
                        </Grid>
                        <Grid item lg={12}>
                            <MediaUpload
                            data-test-id="mediaUploadForFestive"
                            files={(this.state.filesForFestiveMemory.length > 0  && this.state.filesForFestiveMemory[index]) ? this.state.filesForFestiveMemory[index] : festiveMemoriesAttributes.files }
                            onUpload={(event: React.ChangeEvent<HTMLInputElement>) => this.handleFileUpload(event, index, setFieldValue, "festive_memories_attributes")}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            );
        })
        )}
      </FieldArray>
  )};
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { 
        familyDetails,
        showFavouriteMemories,
        showFestiveMemories,
    } = this.state;
    return (
      <ThemeProvider theme={theme}>
        <Wrapper>
          <WebHeader navigation={this.props.navigation} progress={36}/>
          <Box className="containerBox">
            <Box className="sidebarWrapper">
                <AdditionalInformationSidebar handleNavigation={this.handleNavigation} />
            </Box>
            <Box className="familyDetailsWrapper">
                <Box className="pageTilteWrapper">
                    <Typography data-test-id="pageTilteWrapper" variant="h3" className="titleText">
                        {configJSON.familyDetailsTitle}
                    </Typography>
                    <Typography variant="h3" className="messageText">
                        <InfoOutlinedIcon className="infoIcon"/>
                        <span>{configJSON.messageText}</span>
                    </Typography>
                </Box>
                <Box className="formWrapperBox">
                <Formik
                    initialValues={familyDetails}
                    validationSchema={this.validationSchema}
                    onSubmit={(values) => {
                      this.handleFamilyDetailsFormSubmit(values)
                    }}
                    enableReinitialize={true}
                    data-test-id="familyDetailsForm"
                  >
                    {({ values, errors, touched, setFieldValue }) => (
                    <Form>
                      <Box className="formWrapperBox">
                        <Box>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FormLabel component="label" className="questionLabel">
                                        {configJSON.familyDetailsQuestion1}
                                    </FormLabel>
                                    <Field
                                        data-test-id="closestName"
                                        className="inputField multilineInput"
                                        name="closestName"
                                        as={CustomInput}
                                        placeholder="Enter Full name"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setFieldValue(`closest_name`, e.target.value)
                                        }}
                                        value={values.closest_name}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormLabel component="label" className="questionLabel">
                                        {configJSON.familyDetailsQuestion2}
                                    </FormLabel>
                                    <Field
                                        data-test-id="reasonForSpecial"
                                        name="reasonForSpecial"
                                        className="multilineInput"
                                        as={CustomInput}
                                        multiline
                                        placeholder="eg, They are always there when I need cheering up'"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setFieldValue(`reason_for_special`, e.target.value)
                                        }}
                                        value={values.reason_for_special}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormLabel component="label" className="questionLabel">
                                        {configJSON.familyDetailsQuestion3}
                                    </FormLabel>
                                    <Field
                                        data-test-id="about"
                                        name="about"
                                        className="multilineInput"
                                        as={CustomInput}
                                        multiline
                                        placeholder="Eg, No matter what, we are always there for each other"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setFieldValue(`about`, e.target.value)
                                        }}
                                        value={values.about}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormLabel component="label" className="questionLabel">
                                        {configJSON.familyDetailsQuestion4}
                                    </FormLabel>
                                    <RadioGroup
                                        className="radioGroup"
                                        data-test-id="haveFavouriteMemory"
                                        aria-label="haveFavouriteMemory"
                                        name="haveFavouriteMemory"
                                        value={values.have_favourite_memory}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setFieldValue("have_favourite_memory", e.target.value);
                                            this.setState({ showFavouriteMemories: !showFavouriteMemories });
                                        }}
                                    >
                                        <FormControlLabel
                                        className="radioLabel"
                                        value="1"
                                        control={<StyledRadio />}
                                        label="Yes"
                                        />
                                        <FormControlLabel
                                        className="radioLabel"
                                        value="0"
                                        control={<StyledRadio />}
                                        label="No"
                                        />
                                    </RadioGroup>
                                </Grid>
                                { showFavouriteMemories && (
                                    this.renderFavouriteMemoriesForm(values, setFieldValue)
                                )}
                                <Grid item xs={12}>
                                    <FormLabel component="label" className="questionLabel">
                                        {configJSON.familyDetailsQuestion5}
                                    </FormLabel>
                                    <RadioGroup
                                        className="radioGroup"
                                        data-test-id="haveFestiveMemory"
                                        aria-label="haveFestiveMemory"
                                        name="haveFestiveMemory"
                                        value={values.have_festive_memory}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setFieldValue("have_festive_memory", e.target.value);
                                        this.setState({ showFestiveMemories: !showFestiveMemories });
                                        }
                                        }
                                    >
                                        <FormControlLabel
                                        className="radioLabel"
                                        value="1"
                                        control={<StyledRadio />}
                                        label="Yes"
                                        />
                                        <FormControlLabel
                                        className="radioLabel"
                                        value="0"
                                        control={<StyledRadio />}
                                        label="No"
                                        />
                                    </RadioGroup>
                                </Grid>
                                { showFestiveMemories && (
                                    this.renderFestiveMemoriesForm(values, setFieldValue)
                                )}
                                <Grid item xs={12}>
                                    <FormLabel component="label" className="questionLabel">
                                        {configJSON.familyDetailsQuestion6}
                                    </FormLabel>
                                    <Field
                                        data-test-id="description"
                                        name="description"
                                        className="multilineInput"
                                        as={CustomInput}
                                        multiline
                                        placeholder="Max 3 words"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setFieldValue(`description`, e.target.value)
                                        }}
                                        value={values.description}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                        <Box className="btnWrapperBox">
                          <Button
                            data-test-id="skipForNow"
                            variant="outlined"
                            className="btnSize"
                            onClick={
                                () => this.handleNavigation("CustomForm")
                            }
                          >
                            {configJSON.skipForNowBtnText}
                          </Button>
                          <Button
                            className="btnSize"
                            type="submit"
                            variant="contained"
                            data-test-id="saveAndNextBtn"
                          >
                            {configJSON.saveAndNext}
                          </Button>
                        </Box>
                      </Box>
                    </Form>
                    )}
                    </Formik>
                </Box>
            </Box>
          </Box>
          <WebFooter 
            handleNavigation={this.handleNavigation} 
          />
        </Wrapper>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const Wrapper = styled("div")(({ theme }) => ({
  "& .familyDetailsWrapper": {
    width: "100%",
  },
  "& .containerBox": {
    display: "flex",
    gap: "72px",
    padding: "50px 72px",
  },
  "& .pageTilteWrapper": {
    display: "flex",
    justifyContent: "space-between",
    margin: "16px 0",
    alignItems: "center",
  },
  "& .fieldArrayHeaderWrapper": {
    display: "flex",
    justifyContent: "space-between",
  },
  "& .memoriesSubtitle": {
    color: "#475569",
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "24px",
  },
  "& .messageText": {
      color: "#9176C4",
      fontFamily: "Lato",
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "18px",
      display: "flex",
      alignItems: "center",
      gap: "4px",
  },
  "& .infoIcon": {
    fontSize: "20px",
  },
  "& .formWrapperBox": {
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  },
  "& .questionLabel": {
      color: "#1E293B",
      fontFamily: "Lato",
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "22px",
  },
  "& .textMargin": {
    margin: "8px 0px",
  },
  "& .formSectionBackground": {
    backgroundColor: "rgba(206, 195, 229, 0.1)",
    borderRadius: "8px",
    padding: "24px",
  },
  "& .selectField": {
    margin: "5px 0px",
    borderRadius: "8px",
    width: "300px",
    height: "48px",
    display: "block",
  },
  "& .multilineInput": {
    marginLeft: "16px",
  },
  "& .inputField": {
    borderRadius: "8px",
    height: "48px",
    width: "275px",
  },
  "& .radioGroup": {
    display: 'flex',
    gap: "35px",
    flexDirection: 'row',
    marginLeft: "16px",
  },
  "& .radioLabel": {
    fontFamily: "Lato",
    fontSize: "16px",
    color: '#1E293B',
    fontWeight: 400,
    lineHeight: "22px",
    '& .MuiRadio-colorSecondary.Mui-checked': {
      color: '#9176C4',
    },
  },
  "& .btnSize": {
    height: "48px",
    width: "158px",
  },
  "& .btnWrapperBox": {
    display: "flex",
    justifyContent: "flex-end",
    gap: "8px",
    marginTop: "72px",
  },
  "& .circleOutlineIcon": {
    marginRight: "8px",
    fontSize: "16px",
  },
  "& .addAnotherBtn": {
    color: "#9176C4",
    textTransform: "none",
    fontSize: "14px",
    fontFamily: "Lato",
    fontWeight: 700,
    lineHeight: "22px",
    display: "flex",
    alignItems: "center",
    '&:hover': {
      backgroundColor: "transparent",
      textDecoration: "underline",
    },
  },
  [theme.breakpoints.down(740)]: {
    "& .containerBox": {
        padding: "28px 16px",
    },
  },
}));
// Customizable Area End
