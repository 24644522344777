import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { sendAPIRequest } from "../../../components/src/Utils";
import { getStorageData } from "../../../framework/src/Utilities";
import { loadStripe } from "@stripe/stripe-js";

type CurrencySymbolMap = {
  [key: string]: string;
};

export const currencySymbolMap: CurrencySymbolMap = {
  AED: 'د.إ',
  AFN: '؋',
  ALL: 'L',
  AMD: '֏',
  ANG: 'ƒ',
  AOA: 'Kz',
  ARS: '$',
  AUD: '$',
  AWG: 'ƒ',
  AZN: '₼',
  BAM: 'KM',
  BBD: '$',
  BDT: '৳',
  BGN: 'лв',
  BHD: '.د.ب',
  BIF: 'FBu',
  BMD: '$',
  BND: '$',
  BOB: '$b',
  BOV: 'BOV',
  BRL: 'R$',
  BSD: '$',
  BTC: '₿',
  BTN: 'Nu.',
  BWP: 'P',
  BYN: 'Br',
  BYR: 'Br',
  BZD: 'BZ$',
  CAD: '$',
  CDF: 'FC',
  CHE: 'CHE',
  CHF: 'CHF',
  CHW: 'CHW',
  CLF: 'CLF',
  CLP: '$',
  CNH: '¥',
  CNY: '¥',
  COP: '$',
  COU: 'COU',
  CRC: '₡',
  CUC: '$',
  CUP: '₱',
  CVE: '$',
  CZK: 'Kč',
  DJF: 'Fdj',
  DKK: 'kr',
  DOP: 'RD$',
  DZD: 'دج',
  EEK: 'kr',
  EGP: '£',
  ERN: 'Nfk',
  ETB: 'Br',
  ETH: 'Ξ',
  EUR: '€',
  FJD: '$',
  FKP: '£',
  GBP: '£',
  GEL: '₾',
  GGP: '£',
  GHC: '₵',
  GHS: 'GH₵',
  GIP: '£',
  GMD: 'D',
  GNF: 'FG',
  GTQ: 'Q',
  GYD: '$',
  HKD: '$',
  HNL: 'L',
  HRK: 'kn',
  HTG: 'G',
  HUF: 'Ft',
  IDR: 'Rp',
  ILS: '₪',
  IMP: '£',
  INR: '₹',
  IQD: 'ع.د',
  IRR: '﷼',
  ISK: 'kr',
  JEP: '£',
  JMD: 'J$',
  JOD: 'JD',
  JPY: '¥',
  KES: 'KSh',
  KGS: 'лв',
  KHR: '៛',
  KMF: 'CF',
  KPW: '₩',
  KRW: '₩',
  KWD: 'KD',
  KYD: '$',
  KZT: '₸',
  LAK: '₭',
  LBP: '£',
  LKR: '₨',
  LRD: '$',
  LSL: 'M',
  LTC: 'Ł',
  LTL: 'Lt',
  LVL: 'Ls',
  LYD: 'LD',
  MAD: 'MAD',
  MDL: 'lei',
  MGA: 'Ar',
  MKD: 'ден',
  MMK: 'K',
  MNT: '₮',
  MOP: 'MOP$',
  MRO: 'UM',
  MRU: 'UM',
  MUR: '₨',
  MVR: 'Rf',
  MWK: 'MK',
  MXN: '$',
  MXV: 'MXV',
  MYR: 'RM',
  MZN: 'MT',
  NAD: '$',
  NGN: '₦',
  NIO: 'C$',
  NOK: 'kr',
  NPR: '₨',
  NZD: '$',
  OMR: '﷼',
  PAB: 'B/.',
  PEN: 'S/.',
  PGK: 'K',
  PHP: '₱',
  PKR: '₨',
  PLN: 'zł',
  PYG: 'Gs',
  QAR: '﷼',
  RMB: '￥',
  RON: 'lei',
  RSD: 'Дин.',
  RUB: '₽',
  RWF: 'R₣',
  SAR: '﷼',
  SBD: '$',
  SCR: '₨',
  SDG: 'ج.س.',
  SEK: 'kr',
  SGD: 'S$',
  SHP: '£',
  SLL: 'Le',
  SOS: 'S',
  SRD: '$',
  SSP: '£',
  STD: 'Db',
  STN: 'Db',
  SVC: '$',
  SYP: '£',
  SZL: 'E',
  THB: '฿',
  TJS: 'SM',
  TMT: 'T',
  TND: 'د.ت',
  TOP: 'T$',
  TRL: '₤',
  TRY: '₺',
  TTD: 'TT$',
  TVD: '$',
  TWD: 'NT$',
  TZS: 'TSh',
  UAH: '₴',
  UGX: 'USh',
  USD: '$',
  UYI: 'UYI',
  UYU: '$U',
  UYW: 'UYW',
  UZS: 'лв',
  VEF: 'Bs',
  VES: 'Bs.S',
  VND: '₫',
  VUV: 'VT',
  WST: 'WS$',
  XAF: 'FCFA',
  XBT: 'Ƀ',
  XCD: '$',
  XOF: 'CFA',
  XPF: '₣',
  XSU: 'Sucre',
  XUA: 'XUA',
  YER: '﷼',
  ZAR: 'R',
  ZMW: 'ZK',
  ZWD: 'Z$',
  ZWL: '$',
  Pound: '£',
} 

export interface ICheckoutApiResponse {
    id: string,
    object: string,
    after_expiration: string | null,
    allow_promotion_codes: string | null,
    amount_subtotal: number,
    amount_total: number,
    automatic_tax: {
        enabled: boolean,
        liability: string | null,
        status: string | null,
    },
    billing_address_collection: string | null,
    cancel_url: string,
    client_reference_id: string | null,
    client_secret: string | null,
    consent: string | null,
    consent_collection: string | null,
    created: number,
    currency: string,
    currency_conversion: string | null,
    custom_fields: [],
    custom_text: {
        after_submit: string | null,
        shipping_address: string | null,
        submit: string | null,
        terms_of_service_acceptance: string | null,
    },
    customer: string | null,
    customer_creation: string,
    customer_details: {
        address: string | null,
        email: string | null,
        name: string | null,
        phone: string | null,
        tax_exempt: string | null,
        tax_ids: string | null,
    },
    customer_email: string,
    expires_at: number,
    invoice: string | null,
    invoice_creation: string | null,
    livemode: boolean,
    locale: string | null,
    metadata: {},
    mode: string,
    payment_intent: string | null,
    payment_link: string | null,
    payment_method_collection: string,
    payment_method_configuration_details: string | null,
    payment_method_options: {
        card: {
            request_three_d_secure: string | null
        }
    },
    payment_method_types: [],
    payment_status: string,
    phone_number_collection: {
        enabled: boolean,
    },
    recovered_from: string | null,
    saved_payment_method_options: {
        allow_redisplay_filters: [],
        payment_method_remove: string | null,
        payment_method_save: string | null,
    },
    setup_intent: string | null,
    shipping_address_collection: string | null,
    shipping_cost: string | null,
    shipping_details: string | null,
    shipping_options: [],
    status: string,
    submit_type: string | null,
    subscription: string | null,
    success_url: string,
    total_details: {
        amount_discount: number,
        amount_shipping: number,
        amount_tax: number
    },
    ui_mode: string,
    url: string,
};

export interface ISubscriptionPlanDetails {
  id: number,
  name: string,
  price: string,
  plan_type: string,
  currency_name: string,
  description: string,
  image_link: string,
};

export interface IGetSubscriptionPlanAPIResponse {
  id: string,
  type: string,
  attributes: ISubscriptionPlanDetails,
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  route: any;
  data: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  subscriptions: any;
  data: any;
  selectedPlan: number;
  subscriptionPlans: ISubscriptionPlanDetails[];
  openDialog: "subscriptionDone" | "subscriptionFailed" | "subscribeToPlan" | null;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CustomisableusersubscriptionsController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getListCallId: any;
  getSubscriptionPlanListCallId: string = "";
  checkoutApiCallId: string = "";
  stripePromise = loadStripe('pk_test_51OzGO1C4D0Bu5gBia4YiUAHh3O8uWSpb6uViB4pJMUgAaAGudGMUXQjbaelpbuFeKOHZuBCPxeSIdoH7HLfFFPAb00JDTq3D6e');
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      token: "",
      subscriptions: [],
      data: null,
      selectedPlan: 0,
      subscriptionPlans: [],
      openDialog: null,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const data = message.getData(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      this.setState({ data: data });
    }
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token }, () => {
        this.fetchSubscriptionsList();
      });
    }
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.getListCallId) {
          this.setState({
            subscriptions: responseJson.data
          });
        }
      }
    }

    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJSON = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    this.apiSuccessCallBackController(apiRequestCallId, responseJSON);
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    const searchParams = new URLSearchParams(window.location.search);
    const status = searchParams.get("status");
    if(status === "success"){
      this.setState({ openDialog: "subscriptionDone" });
    }
    else if(status === "cancel"){
      this.setState({ openDialog: "subscriptionFailed" });
    }
    this.getSubscriptionPlanList();
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  fetchSubscriptionsList = () => {
    // Customizable Area Start
    const header = {
      token: this.state.token,
      "Content-Type": configJSON.subscriptionApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getListCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSubscriptionAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSubscriptionAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
  };

  gotoSubDetailScreen(item:any) {
    // Customizable Area Start
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "SubscriptionDetails");
    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), item);
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg)
    // Customizable Area End
  }

  // Customizable Area Start
  getSubscriptionPlanList = async () => {
    const token = await getStorageData("token");
    
    this.getSubscriptionPlanListCallId = sendAPIRequest(
        configJSON.getSubscriptionPlanAPiEndPoint,
        {
          method: configJSON.getSubscriptionAPiMethod,
          headers: {
            token: token,
          },
        }
      )
  }

  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJSON: Record<string, unknown>
  ) => {
    const successCallbackMap = {
      [this.getSubscriptionPlanListCallId]: this.handleGetSubscriptionPlanApiResponse,
      [this.checkoutApiCallId]: this.handleCheckoutApiResponse,
    }
 
    if (apiRequestCallId) {
      const successCallback: (responseJSON: Record<string, unknown>) => void = successCallbackMap[apiRequestCallId]
      !!successCallback && successCallback(responseJSON)
    }
  }

  handleGetSubscriptionPlanApiResponse = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) return;

    const response = responseJSON as {
      meta?: { message: string };
      data?: IGetSubscriptionPlanAPIResponse[];
    };

    if (response.data) {
      let subscriptionPlans: ISubscriptionPlanDetails[] = [];

      response.data.forEach((item) => {
        subscriptionPlans.push(item.attributes);
      });

      this.setState({ subscriptionPlans });
    }
  };

  handleCheckoutApiResponse = async(responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) return;

    const response = responseJSON as {
      checkout?: ICheckoutApiResponse;
    };

    if(response.checkout){
      const stripe = await this.stripePromise;
      const result = await stripe?.redirectToCheckout({
        sessionId: response.checkout.id,
      });

      if (result && result.error) {
        // Handle error here
      }
    };
  };

  handleErrorResponse = (responseJSON: Record<string, unknown>) => {
    const { errors: possibleErrors } = responseJSON;
    if (possibleErrors) {
      return true; // Indicates that there was an error
    }
    return false; // Indicates that there was no error
  };

  handleSubscribeBtnClick = async() => {
    const token = await getStorageData("token");
    
    this.checkoutApiCallId = sendAPIRequest(
        configJSON.checkoutApiEndPoint,
        {
          method: configJSON.postMethodType,
          headers: {
            'Content-Type': 'application/json',
            token: token,
          },
          body: {
            "subscription_id": this.state.selectedPlan,
          }
        }
      );
  };

  handleContinueBtnClick = () => {
    this.handleNavigation("CustomForm");
  };

  handleTryAgainBtnClick = () => {
    this.setState({ openDialog: null });
  };

  handlePlanSelect = (planId: number) => {
    this.setState({ selectedPlan: planId });
  };

  handleCloseDialog = () => {
    this.setState({ openDialog: null });
  };

  handleNavigation = (route: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), route);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };
  // Customizable Area End
}
