import React from "react";

import {
  Container,
  Box,
  Typography,
  Breadcrumbs,
  Link,

  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import NavigateNextIcon from '@material-ui/icons/NavigateNext'; 
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import WebHeader from "../../landingpage/src/WebHeader.web";
import WebFooter from "../../../components/src/Footer.web"
// src/packages/components/src/webHeader.web.tsx"
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import PrivacypolicyController, {
  Props,
} from "./PrivacypolicyController.web";
import moment from "moment";

export default class Privacypolicy extends PrivacypolicyController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { PrivacyPolicy } = this.state;
    const lastUpdatedDate = PrivacyPolicy?.privacy_policy?.updated_at 
    ? moment(PrivacyPolicy.privacy_policy.updated_at).format('DD MMMM YYYY') 
    : '';
    const privacyDescription = PrivacyPolicy ? PrivacyPolicy.privacy_policy.description: 'Loading...';

    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Box>
        <WebHeader navigation={this.props.navigation} />  
        <Container maxWidth={"xl"} >
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" style={webStyle.breadBox}>
                <Link  href="/" style={{color:"#475569",fontFamily:"Lato",fontSize:"16px",fontWeight:500}}>
                  Legal
                </Link>

                <Typography color="textPrimary" style={{color:"#0F172A",fontFamily:"Lato",fontSize:"16px",fontWeight:600}}>Privacy Policy</Typography>
              </Breadcrumbs>        
          <Box>
          <Box className="terms-of-use" style={{width:"95%"}}>
            <Box style={{marginTop:"4%"}}><Typography  style={{color:"#0F172A",fontSize:"40px",fontWeight:700,fontFamily:"Quattrocento"}}  align="center" >
               Privacy Policy
              </Typography>
              </Box>
              <Typography  align="center" gutterBottom  style={webStyle.dateBox}>
                <em>Last updated: {lastUpdatedDate} </em>
              </Typography>
              <Box dangerouslySetInnerHTML={{ __html: privacyDescription }} />
            </Box>
          </Box> 
        </Container>
        <WebFooter navigation={this.handleLogInNavigation}  data-test-id="HandelHeader"/>
        </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    paddingBottom: "30px",
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    background: "#fff",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
    marginTop: "40px",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100px",
  },
  dateBox:{
   color:"#1E293B",
   fontSize:"24px",
   fontWeight:700,
   fontFamily:"Lato",
   marginBottom:"40px"
  },
  breadBox:{
    marginTop:"35px"
   }

};
// Customizable Area End
