import React from "react";
import { styled } from "@material-ui/core";
import { SvgIconComponent } from "@material-ui/icons";
import "react-datepicker/dist/react-datepicker.css";
import { getMonth, getYear } from "date-fns";
import CustomInput from "./CustomInput";
import { Component } from "react";
import DatePicker from "react-datepicker";

interface Props {
  placeholder: string;
  value: string;
  startDate: Date | null;
  endDate: Date | null;
  monthsShown?: number;
  onChange: (dates: [Date | null, Date | null] | Date | null) => void;
  className?: string;
  startIcon?: SvgIconComponent;
  endIcon?: any;
  error?: boolean;
  errorMsg?: string;
  ageRestriction?: number;
  minDate?: Date | null;
}

interface State {
  isOpen: boolean;
}

export default class CustomDatePicker extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  CustomHeader = ({ 
    date, 
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled, 
  }: any) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const years = Array.from({ length: 101 }, (_, i) => i + 1950); // Years from 1950 to 2050

    return (
      <CustomheaderWrapper>
        <div className="calendar-header">
        <button type="button" className="nav-button" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
          <span className="spanIcon spanIconPrevious"></span>
        </button>
        <select
          value={months[getMonth(date)]}
          onChange={({ target: { value } }: any) =>
            changeMonth(months.indexOf(value))
          }
        >
          {months.map((month, index) => (
            <option key={month} value={month}>
              {month}
            </option>
          ))}
        </select>
        <select
          value={getYear(date)}
          onChange={({ target: { value } }) => changeYear(value)}
        >
          {years.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
        <button type="button" className="nav-button"  onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
          <span className="spanIcon spanIconNext"/>
        </button>
      </div>
      </CustomheaderWrapper>
    );
  };

  handleToggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  handleChange = (date: Date | null) => {
    this.props.onChange(date);
    this.setState({ isOpen: false });
  };

  render() {
    const {
      placeholder,
      value,
      className = "",
      startIcon: StartIcon,
      endIcon: EndIcon,
      error = false,
      errorMsg = "",
      startDate,
      endDate,
      monthsShown = 1,
      ageRestriction,
      minDate,
    } = this.props;
    const { isOpen } = this.state;
    const maxDate = new Date();
    if(ageRestriction){
      maxDate.setFullYear(maxDate.getFullYear() - ageRestriction);
    }

    return (
      <Wrapper>
        <CustomInput
          size="small"
          placeholder={placeholder}
          value={value}
          startIcon={StartIcon}
          endIcon={EndIcon}
          onClick={this.handleToggle}
          className={className}
          error={error}
          errorMsg={error ? errorMsg : ""}
        />
        <DatePicker
          startDate={startDate}
          endDate={endDate}
          onChange={
            this.handleChange
          }
          onClickOutside={this.handleToggle}
          selectsRange={monthsShown > 1}
          monthsShown={monthsShown}
          open={isOpen}
          maxDate={ageRestriction ? maxDate : null}
          minDate={minDate}
          renderCustomHeader={this.CustomHeader}
          showMonthDropdown
          showYearDropdown
        />
      </Wrapper>
    );
  }
}

const Wrapper = styled("div")(({ theme }) => ({
  "& .react-datepicker__input-container": {
    display: "none",
  },
  "& .react-datepicker": {
    padding: "0 15px",
  },
  "& .react-datepicker-popper": {
    padding: 0,
  },
  "& .react-datepicker__triangle": {
    display: "none",
  },
  "& .react-datepicker__header": {
    backgroundColor: "transparent",
    border: "none",
  },
  "& .react-datepicker__navigation--previous": {
    margin: "15px 25px",
  },
  "& .react-datepicker__navigation--next": {
    margin: "15px 25px",
  },
  "& .react-datepicker__current-month": {
    fontFamily: "Lato",
    fontWeight: 700,
    color: "#0F172A",
    padding: "10px",
  },
  "& .react-datepicker__day-names": {
    fontFamily: "Lato",
  },
  "& .react-datepicker__day-name": {
    padding: "0 5px",
    color: "#64748B",
  },
  "& .react-datepicker__day": {
    fontFamily: "Lato",
    padding: "5px",
    "&:hover": {
      backgroundColor: "#9176C4",
      color: "#fff",
      borderRadius: "50%",
      borderColor: "#9176C4",
    },
  },
  "& .react-datepicker__day--keyboard-selected": {
    backgroundColor: "#9176C4",
    color: "#fff",
    borderRadius: "50%",
  },
  "& .react-datepicker__day--in-range": {
    backgroundColor: "#4F9FF8",
    color: "#fff",
    borderRadius: "50%",
  },
  "& .react-datepicker__day--in-selecting-range": {
    backgroundColor: "#4F9FF8",
    color: "#fff",
    borderRadius: "50%",
  },
  "& .react-datepicker__day--outside-month": {
    backgroundColor: "transparent",
  },
  [theme.breakpoints.down(650)]: {
    "& .react-datepicker": {
      padding: 0,
    },
    "& .react-datepicker__navigation--previous": {
      margin: "10px",
    },
    "& .react-datepicker__navigation--next": {
      margin: "10px",
    },
  },
  [theme.breakpoints.down(630)]: {
    "& .react-datepicker": {
      display: "grid",
    },
  },
}));

const CustomheaderWrapper = styled("div")(({ theme }) => ({
  "& .calendar-header": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px",
    borderBottom: "1px solid #ccc",
    fontFamily: "Lato",
  },
  "& .calendar-header>select": {
    margin: "0 5px",
    padding: "8px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    backgroundColor: "white",
  },
  "& .nav-button": {
    background: "none",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    cursor: "pointer",
    position: "relative",
    top: "2px",
    padding: 0,
    border: "none",
    zIndex: 1,
    height: "32px",
    width: "32px",
    overflow: "hidden",
  },
  "& .spanIcon": {
    borderColor: "#ccc",
    borderStyle: "solid",
    borderWidth: "3px 3px 0 0",
    content: "",
    display: "block",
    height: "9px",
    position: "absolute",
    top: "6px",
    width: "9px",
  },
  "& .spanIconPrevious": {
    transform: "rotate(220deg)",
  },
  "& .spanIconNext": {
    transform: "rotate(45deg)",
  },
  "& .calendar-header>select>option": {
    "&:hover": {
      backgroundColor: "#9176C4",
    },
  }
}));


